export const MuiFormControl = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({}),
    },
};
export const MuiFormControlLabel = {
    styleOverrides: {
        label: ({ ownerState, theme }) => ({
            '&.Mui-disabled': {
                opacity: '.7',
            },
        }),
    },
};
