// TODO: to delete later
// Initial enum with `PIcked`.
// export enum StickerState {
// 	Receipted = 1,
// 	Placed = 2,
// 	Picked = 3,
// 	Packed = 4,
// 	Shipped = 5,
// }
// TODO check if we can move this to Warehouse from shared. (And maybe StickerItemBase too)
export var StickerState;
(function (StickerState) {
    StickerState[StickerState["Unknown"] = 0] = "Unknown";
    StickerState[StickerState["Receipted"] = 1] = "Receipted";
    StickerState[StickerState["Placed"] = 2] = "Placed";
    // This is probably should be `Packed`
    // Picked = 3,
    StickerState[StickerState["Packed"] = 3] = "Packed";
    // This is probably should be `AddedToRealization`
    //Packed = 4,
    /**
     * Добавленные в реализацию
     */
    StickerState[StickerState["AddedToRealization"] = 4] = "AddedToRealization";
    StickerState[StickerState["Shipped"] = 5] = "Shipped";
    // Some more states below (are they in use?).
    /** Placed on a stock. Nothing else can be done with stickers in this state. */
    StickerState[StickerState["PlacedOnStock"] = 6] = "PlacedOnStock";
    /** Returned by a customer. */
    // ReceiptedReturnFromCustomer = 7, // Legacy name
    /**  */
    StickerState[StickerState["ReturnFromCustomer"] = 7] = "ReturnFromCustomer";
    /** Cancelled by a customer. */
    StickerState[StickerState["CanceledByCustomer"] = 8] = "CanceledByCustomer";
    //Принято в ПВЗ
    // ReceivedDeliverypoint = 9, // Legacy name
    /** Received at a delivery point. */
    StickerState[StickerState["ReceivedAtDeliveryPoint"] = 9] = "ReceivedAtDeliveryPoint";
    //Выдали получателю в ПВЗ
    //GivenToCustomer = 10, // Legacy name
    /**  */
    StickerState[StickerState["HandedToCustomer"] = 10] = "HandedToCustomer";
    // //Отправили через сервис доставки
    // SendToPartnerDeliveryService = 11, // Legacy name
    /**  */
    StickerState[StickerState["SentToPartnerDeliveryService"] = 11] = "SentToPartnerDeliveryService";
})(StickerState || (StickerState = {}));
// OLD FRONT STICKER STATE:
// export const STICKER_STATE = {
// 	None: 0,
// 	//Принятые
// 	Receipted: 1,
// 	//Размещенные
// 	Placed: 2,
// 	//Собранные
// 	Picked: 3,
// 	//Добавленные в реализацию
// 	AddedToRealization: 4,
// 	//Отгруженные
// 	Shipped: 5,
// 	//Размещенные на сток - с ними нихуя больше делоть нельзя
// 	StockPlaced: 6,
// 	//Вернул пользователь
// 	ReceiptedReturnFromCustomer: 7,
// 	//Отменил пользователь
// 	CanceledByCustomer: 8,
// 	//Принято в ПВЗ
// 	ReceivedDeliverypoint: 9,
// 	//Выдали получателю в ПВЗ
// 	GivenToCustomer: 10,
// 	//Отправили через сервис доставки
// 	SendToPartnerDeliveryService: 11,
// };
