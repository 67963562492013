import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useContext, useEffect } from 'react';
import { ActionIconsContext } from '../ActionIcons.context';
import { IconButtonStyled } from './ActionIconTemplate.styled';
import { Link as RouterLink } from 'react-router-dom';
import { SvgIcon } from 'core/ui';
import { Tooltip } from '@mui/material';
const defaultActionIconTooltipEnterDelay = 600;
export const ActionIconTemplate = forwardRef(({ icon, size, title, disabled, color, defaultTitle, route, onClick, ...props }, ref) => {
    const containerContext = useContext(ActionIconsContext);
    useEffect(() => {
        if (!containerContext) {
            throw new Error('ActionIcon must be used as a child of ActionIcons component.');
        }
    });
    const { disabled: containerDisabled, color: containerColor, iconSize, tooltipEnterDelay, tooltipLeaveDelay, buttonComponent, } = containerContext ?? {};
    const routeOrOnClickProps = route ? { component: RouterLink, to: route } : onClick ? { onClick } : {};
    return (_jsx(Tooltip, { title: title ?? defaultTitle, enterDelay: tooltipEnterDelay ?? defaultActionIconTooltipEnterDelay, leaveDelay: tooltipLeaveDelay, children: _jsx(IconButtonStyled, { component: buttonComponent, tabIndex: -1, ref: ref, ...props, ...routeOrOnClickProps, disabled: disabled ?? containerDisabled, onClick: onClick, customColor: color ?? containerColor, children: _jsx(SvgIcon, { icon: icon, size: size ?? iconSize }) }) }));
});
