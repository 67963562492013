import { DialogContent, styled } from '@mui/material';
import { shouldNotForwardProps } from 'shared/helpers';
export const DialogContentContainer = styled('div')({
    display: 'flex',
    flex: '1',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
});
/**
 * Prevents dialog content flashing before Spinner is shown.
 *
 * @description Since we need to know what height dialog should be while data is loading and spinner is shown, we should have all dialog controls to
 * exist inside dialog content, and show spinner above it.
 * Without controls dialog is opened with minimum height, and after content is loaded height becomes full.
 *
 * @param isLoading required to know if content should be hidden
 */
export const LoadingDialogContent = styled(DialogContent, shouldNotForwardProps('isLoading'))(({ theme, isLoading }) => ({
    visibility: isLoading ? 'hidden' : undefined,
}));
