import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { ReceiptSubmissionSnackbar, SimpleSnackbar } from 'shared/components';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { Suspense, useMemo } from 'react';
import { getPalette, themeOptions } from 'core/theme';
import { maxSnackbars, snackbarAutoHideDuration } from './constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ConfirmDialogProvider } from 'core/hooks';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { router } from './navigation';
import { ru } from 'date-fns/locale/ru';
import { setDefaultOptions } from 'date-fns/setDefaultOptions';
setDefaultOptions({ locale: ru });
const mode = 'light';
const russianLocale = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;
export const App = () => {
    const theme = useMemo(() => createTheme({ ...themeOptions, ...getPalette(mode) }), []);
    return (_jsx(_Fragment, { children: _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: ru, localeText: russianLocale, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx(GlobalStyles, { styles: {
                                html: { height: '100%' },
                                body: { height: '100%' },
                                '#root': {
                                    minHeight: '100%',
                                },
                                // Original fontawesome classes
                                '@keyframes fa-spin': {
                                    '0%': {
                                        transform: 'rotate(0deg)',
                                    },
                                    '100%': {
                                        transform: 'rotate(360deg)',
                                    },
                                },
                                '.fa-spin': {
                                    animationName: 'fa-spin',
                                    animationDelay: 'var(--fa-animation-delay, 0s)',
                                    animationDirection: 'var(--fa-animation-direction, normal)',
                                    animationDuration: 'var(--fa-animation-duration, 2s)',
                                    animationIterationCount: 'var(--fa-animation-iteration-count, infinite)',
                                    animationTimingFunction: 'var(--fa-animation-timing, linear)',
                                },
                                ':focus-visible': {
                                    outline: '-webkit-focus-ring-color auto 2px',
                                },
                                '&.Mui-focusVisible': {
                                    outlineColor: theme.palette.primary.dark,
                                    outlineOffset: '2px',
                                    outlineWidth: '2px',
                                    outlineStyle: 'solid',
                                    zIndex: theme.zIndex.fab,
                                },
                            } }), _jsx(SnackbarProvider, { anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, maxSnack: maxSnackbars, autoHideDuration: snackbarAutoHideDuration, Components: {
                                default: SimpleSnackbar,
                                success: SimpleSnackbar,
                                info: SimpleSnackbar,
                                warning: SimpleSnackbar,
                                error: SimpleSnackbar,
                                receiptSubmissionSuccess: ReceiptSubmissionSnackbar,
                                receiptSubmissionError: ReceiptSubmissionSnackbar,
                                receiptBlockSuccess: ReceiptSubmissionSnackbar,
                            } }), _jsx(ConfirmDialogProvider, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, { children: "Loading..." }), children: _jsx(RouterProvider, { router: router }) }) })] }) }) }) }));
};
