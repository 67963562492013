import { alpha, styled } from '@mui/material';
export const DescriptionList = styled('dl')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'min(50%, 20rem) auto',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: '1.5rem',
    margin: 0,
}));
const getBaseStyles = (theme) => ({
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderColor: alpha(theme.palette.text.primary, 0.05),
    paddingTop: '.75rem',
    paddingBottom: '.75rem',
    margin: 0,
    // @ts-expect-error What type to choose to allow ':first-of-type'?
    ':first-of-type': {
        borderStyle: 'none',
    },
});
export const DescriptionTerm = styled('dt')(({ theme }) => ({
    ...getBaseStyles(theme),
    gridColumnStart: 1,
    color: theme.palette.text.muted,
}));
export const DescriptionDetails = styled('dd')(({ theme }) => ({
    ...getBaseStyles(theme),
    color: theme.palette.text.primary,
}));
