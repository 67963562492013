import { menuClasses, popoverClasses } from '@mui/material';
import { menuPaperTopOffset } from 'core/constants';
// TODO: to delete all redundant commented code. after redesign
export const MuiMenu = {
    styleOverrides: {
        paper: ({ ownerState, theme }) => ({
            // We have to use !important because MuiMenu-paper got overridden by MuiPopover-paper by default.
            // boxShadow:
            // 	'#fff 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important',
            // We have to use !important because MuiMenu-paper got overridden by MuiPopover-paper by default.
            boxShadow: 
            // `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(9, 9, 11, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important`,
            `${theme.palette.common.white} 0px 0px 0px 0px, ${theme.palette.divider} 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important`,
            outlineColor: 'transparent',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            // This doesn't work without `!important` because overridden with inline styles further.
            transform: `translateY(${menuPaperTopOffset}) !important`,
        }),
        list: {
            minWidth: '16rem',
            '.MuiDivider-root': {
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
            },
        },
    },
};
export const MuiMenuItem = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            '&[hidden]': { display: 'none' },
            '.MuiListItemIcon-root': {
                minWidth: '2.25rem',
            },
            ':hover': {
                backgroundColor: theme.palette.background.default,
            },
            // '.MuiPopover-paper.MuiMenu-paper &': {
            [`.${popoverClasses.paper}.${menuClasses.paper} &`]: {
                ':focus-visible': {
                    outline: 'none',
                    // background: 'red',
                },
            },
        }),
    },
};
