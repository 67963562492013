const core = {
    'moment.locale': 'ru',
    $: '₽',
    'fa-currency': 'ruble-sign',
    N: '№',
    'subsystem.title.adm': 'Панель администрирования',
    'subsystem.title.wh': 'Управление складом',
    'subsystem.title.sup': 'Панель поставщика',
    'green.color': 'Зелёный',
    'green.color.feminine': 'Зелёная',
    'blue.color': 'Синий',
    'blue.color.feminine': 'Синяя',
    'purple.color': 'Фиолетовый',
    'purple.color.feminine': 'Фиолетовая',
    'orange.color': 'Оранжевый',
    'orange.color.feminine': 'Оранжевая',
    'sign.in.label': 'Войти',
    'sign.in.noun': 'Вход',
    'login.label': 'Логин',
    'registration.label': 'Регистрация',
    'phone.label': 'Телефон',
    'phone.number.label': 'Номер телефона',
    'enter.password.label': 'Введите пароль',
    'reset.password.label': 'Сбросить пароль',
    'change.password.label': 'Сменить пароль',
    'password.change.label': 'Смена пароля',
    'restore.password': 'Восстановить пароль',
    'forget.password.label': 'Забыли пароль ?',
    'current.password.label': 'Текущий пароль',
    'enter.current.password': 'Введите текущий пароль',
    'new.password.label': 'Новый пароль',
    'confirm.new.password.label': 'Подтвердите новый пароль',
    'passwords.do.not.match': 'Пароли не совпадают',
    'confirm.the.password': 'Подтвердите пароль',
    'current.password.error1': 'Введите текущий пароль (от 6 до 10 символов)',
    'new.password.error1': 'Введите новый пароль (не менее 6 символов)',
    'at.least.6.characters': 'не менее 6 символов',
    'at.least.number.characters': 'не менее {1} символов',
    'enter.realphone.label': 'Укажите ваш настоящий номер телефона',
    'enter.your.email': 'Укажите ваш email',
    'enter.email.while.register': 'Введите email, указанный при регистрации',
    'enter.your.password.recovery.email': 'Введите ваш Email для восстановления пароля',
    'enter.code.from.sms': 'Введите код из SMS',
    'change.phone.number.label': 'Изменить номер телефона',
    'send.confirmation.code': 'Выслать код подтверждения',
    'confirm.label': 'Подтвердить',
    'cancel.label': 'Отмена',
    'close.label': 'Закрыть',
    'submit.label': 'Принять',
    // 'submit.all.label': 'Принять все',
    'accept.label': 'Принять',
    'delete.label': 'Удалить',
    'back.label': 'Назад',
    'remove.away.label': 'Убрать',
    'choose.label': 'Выбрать',
    'add.label': 'Добавить',
    'edit.label': 'Редактировать',
    'edit.sm.label': 'Изменить',
    'save.label': 'Сохранить',
    'change.label': 'Изменить',
    'change.2.label': 'Сменить',
    'reset.label': 'Сбросить',
    'check.label': 'Проверить',
    'find.label': 'Найти',
    'email.label': 'Электронная почта',
    'email.sm.label': 'Эл. почта',
    'password.label': 'Пароль',
    'domain.label': 'Домен',
    'sending.label': 'Отправка',
    'loading.label': 'Загрузка',
    'loading.ellipsis.label': 'Загрузка...',
    'view.label': 'Посмотреть',
    'create.label': 'Создать',
    'refresh.label': 'Обновить',
    'update.label': 'Обновить',
    'disable.label': 'Отключить',
    'enable.label': 'Подключить',
    'download.label': 'Скачать',
    'browse.label': 'Обзор',
    'copy.label': 'Копировать',
    'settings.label': 'Настройки',
    'userid.label': 'ID пользователя',
    'balance.label': 'Баланс',
    'sign.out.label': 'Выход',
    'summary.label': 'Итого',
    'file.label': 'Файл',
    'total.label': 'Всего',
    'total.label.currency': 'Всего, ₽',
    'date.label': 'Дата',
    'operation.label': 'Операция',
    'return.noun': 'Возврат',
    'reason.label': 'Причина',
    'reason.base.label': 'Основание',
    'wait.label': 'Подождите',
    'action.label': 'Действие',
    'available.label': 'Доступно',
    'not.available.label': 'Не доступно',
    'full.name.abbr': 'ФИО',
    'sex.label': 'Пол',
    'male.sex.label': 'Мужской',
    'female.sex.label': 'Женский',
    'last.name.label': 'Фамилия',
    'first.name.label': 'по',
    'middle.name.label': 'Отчество',
    'export.to.label': 'Экспорт в',
    'search.label': 'Поиск',
    'nothing.found.label': 'Ничего не найдено',
    'period.label': 'Период',
    'arbitrarily.label': 'Произвольно',
    'days.label': 'Дней',
    'days.label.lowercase': 'дней',
    'day.label': 'день',
    'week.label': 'неделя',
    'month.label': 'месяц',
    'year.label': 'год',
    'id.label': 'ID',
    'name.label': 'по',
    'name.lg.label': 'Наименование',
    //"title.label": "Название",
    'name.md.label': 'Название',
    'description.label': 'Описание',
    'comment.label': 'Комментарий',
    'comment.short.label': 'Коммент',
    'supplier.label': 'Поставщик',
    'manufacturer.label': 'Производитель',
    'client.label': 'Клиент',
    'customer.label': 'Заказчик',
    'delivery.time.short': 'Срок',
    'qty.short': 'Кол-во',
    'qty.short.comma.pcs': 'Кол-во, шт.',
    'price.label': 'Цена',
    'price.comma.currency': 'Цена, ₽',
    'price.for.one': 'Цена за шт',
    'selling.price': 'Цена продажи',
    'selling.price.comma.currency': 'Цена продажи, ₽',
    'sum.label': 'Сумма',
    'sum.comma.currency': 'Сумма, ₽',
    'sum-amount.label': 'Сумма',
    'sum-amount.comma.currency': 'Сумма, ₽',
    'cost.price': 'Себестоимость',
    'cost.price.comma.currency': 'Себестоимость, ₽',
    'margin.label': 'Маржа',
    'margin.comma.percent': 'Маржа, %',
    'margin.comma.currency': 'Маржа, ₽',
    'activation.label': 'Активация',
    'time.comma.days': 'Срок, дн.',
    'availability.comma.pieces': 'Наличие, шт.',
    // Security block
    'token.label': 'Токен',
    'access.label': 'Доступ',
    'access.denied.label': 'Доступ запрещен',
    'user.does.not.have.enough.access.rights': 'Пользователь не имеет достаточно прав для доступа к запрашиваемым данным',
    'you.dont.have.permission.to.access.the.contents.of.this.page': 'Не достаточно прав доступа к содержимому данной страницы.',
    'go.to.the.main.page': 'Перейти на главную страницу',
    'no.data.label': 'Нет данных',
    'order.no.label': '№ заказа',
    'order.id.label': 'ID заказа',
    'order.date.label': 'Дата заказа',
    'status.label': 'Статус',
    'purchase.label': 'Закупка',
    'selling.label': 'Продажа',
    'counterparty.label': 'Контрагент',
    'to.pay.label': 'К оплате',
    'paid.label': 'Оплачено',
    'we.have.to.pay.sum.cur': 'Мы должны заплатить, сумма, ₽',
    'we.must.be.paid.sum.cur': 'Нам должны заплатить, сумма, ₽',
    'document.label': 'Документ',
    'deferment.label': 'Отсрочка',
    'counterparty.total.debt': 'Общий долг контрагента',
    'amounts.of.payments.entire': 'Суммы оплат за весь период',
    'payments.today': 'Платежи сегодня',
    'advance.payment': 'Аванс',
    'order.amount': 'Сумма заказов',
    'unshipped.orders.amount': 'Сумма неотгруженных заказов',
    'planned.label.plural': 'Плановые',
    'planned.label.feminine': 'Плановая',
    'overdue.feminine': 'Просроченная',
    'counterparty.name.lg': 'Название контрагента',
    'type.label': 'Тип',
    'collected.label': 'Собрано',
    'created.label': 'Создан',
    'place.label': 'Место',
    'places.label': 'Мест',
    'vendor.code': 'Артикул',
    'pricelist.label': 'Прайс-лист',
    'amount.label': 'Количество',
    'cash.label': 'Наличные',
    'pick.up.label': 'Самовывоз',
    'giveawayWithoutPincode.label': 'Выдавать без пинкода',
    'canGroupOrders.label': 'Группировка заказов',
    'universal.transmission.document': 'Универсальный передаточный документ',
    'expand.menu.label': 'Развернуть меню',
    'collapse.menu.label': 'Свернуть меню',
    'utd.abbr': 'УПД',
    'legal.abbr': 'Юр. лицо',
    'pieces.abbr': 'шт.',
    'days.abbr': 'дн.',
    'hours.abbr': 'ч.',
    'qrcode.abbr': 'ШК',
    'city.abbr': 'г.',
    'house.abbr': 'д.',
    'street.abbr': 'ул.',
    'apartment.abbr': 'кв.',
    'vat.abbr': 'НДС',
    'in.preposition': 'за',
    'from.preposition': 'с',
    'to.preposition': 'по',
    'dated.label': 'от',
    'display.list': 'Список',
    'display.as.list': 'Списком',
    'display.by.cells': 'По ячейкам',
    'display.by.customers': 'По клиентам',
    'display.by.suppliers': 'По поставщикам',
    'display.by.counterparties': 'По контрагентам',
    'in.work.status': 'В работе',
    'canceled.status': 'Отменён',
    'ready.status': 'Готов',
    'archive.status': 'Архив',
    'archive.label': 'Архив',
    'canceling.status': 'Отменяется',
    'refund.requested.status': 'Запрошен возврат',
    'issued.status': 'Выдан',
    'expected.in.stock': 'Ожидается на складе',
    'expected.in.stock.short': 'Ожидается на скл',
    'taken.in.stock': 'Принят на складе',
    'cancel.the.order': 'Отменить заказ',
    'make.a.refund': 'Сделать возврат',
    're-order.label': 'Перезаказать',
    'to.archive.label': 'В архив',
    'take.label': 'Принять',
    'refuse.label': 'Отказаться',
    'more.details.label': 'Подробнее',
    'add.comment.label': 'Добавить комментарий',
    'enter.comment': 'Укажите комментарий',
    'deletion.confirmation.label': 'Подтверждение удаления',
    'deletion.label': 'Удаление',
    'u.sure.delete.label': 'Вы уверены, что хотите удалить',
    'button.label': 'Кнопка',
    'personal.label': 'Физические лица',
    'legals.label': 'Юридические лица',
    'legal.label': 'Юридическое лицо',
    'position.label': 'Должность',
    'logo.label': 'Логотип',
    'choosefile.label.droparea': 'Выберите файл на компьютере',
    'ordrop.label.droparea': 'или перетащите его сюда',
    'format.less1mb.label.droparea': 'Формат png, gif, jpg. Размер до 1Мб.',
    'no.more.than.count.pcs': 'Не более {1} шт.',
    'personal.data.label': 'Персональные данные',
    'primary.data.label': 'Основные данные',
    'birthday.date.label': 'Дата рождения',
    'roles.label': 'Роли',
    'no.roles.assigned': 'Роли не назначены',
    'data.ready.to.send': 'Данные готовы к отправке',
    'general.label': 'Основное',
    'companies.label': 'Компании',
    'legal.type': 'Тип юридического лица',
    'legal.type.short': 'Тип юр.лица',
    'add.company.label': 'Добавить компанию',
    'edit.company': 'Редактировать компанию',
    'addresses.label': 'Адреса',
    'delivery.addresses': 'Адреса доставки',
    'add.address': 'Добавить адрес',
    'add.email': 'Добавить email',
    'linked.accounts.str': 'Связанные аккаунты в соц.сетях',
    'unlink.social.network.qu': 'Отвязать соц. сеть ? ',
    'subscribe.to.notifications': 'Подписка на уведомления',
    'notification.time': 'Время получения уведомлений',
    'for.documents.label': 'Для документов',
    'order.notifications': 'Уведомления по заказам',
    'from.an.individual': 'От физического лица',
    'on.behalf.of.company': 'От лица компании',
    'which.company.to.place.an.order.for': 'На какую компанию оформлять заказ ?',
    'delivery.address': 'Адрес доставки',
    'locality.label': 'Населенный пункт',
    'street.label': 'Улица',
    'house.label': 'Дом',
    'house.block.label': 'Корпус',
    'house.building.label': 'Строение',
    'apartment.slash.office': 'Квартира / офис',
    'contract.label': 'Договор',
    'contract.no': 'Договор №',
    'terms.of.use': 'Условия пользования',
    'terms.of.use.instrumental.case': 'Условиями пользования',
    'feedback.label': 'Обратная связь',
    'offer.out.of.date': 'Предложение устарело',
    'find.another.offer': 'Найти другое предложение',
    'no.payment.found': 'Оплаты не найдены',
    'change.search.and.try.again': 'Измените критерии поиска и попробуйте снова',
    'your.name.label': 'Ваше по',
    'message.label': 'Сообщение',
    'order.label': 'Заказ',
    'order.num.label': 'Заказ №',
    'order.genitive.lower': 'заказа',
    'return.noun.genitive.lower': 'возврата',
    'peaces.available.for.order': 'Доступно для {1} только {2} шт',
    'by.default': 'по-умолчанию',
    'use.by.default': 'использовать по-умолчанию',
    'remember.my.choice': 'Запомнить мой выбор',
    'goods.label': 'Товары',
    'delivery.label': 'Доставка',
    'free.label': 'бесплатно',
    'agree.with': 'Согласен с ',
    'pay.order': 'Оплатить заказ',
    'change.shipping.method': 'Изменить способ доставки',
    'delivery.point': 'Точка доставки',
    'delivery.point.abbr': 'ПВЗ',
    'delivery.point.all': 'Пункты выдачи заказов',
    'select.delivery.method': 'Выберите способ доставки',
    'pick-up.point': 'Место выдачи',
    'select.placeholder': 'Выберите...',
    // 'pick.a.date.placeholder': 'Выберите дату',
    'pick.a.date.range.placeholder': 'Выберите диапазон дат',
    'no.options.dropdown.label': 'Список пуст',
    'city.label': 'Город',
    'open.label': 'Открыто',
    'closed.label': 'Закрыто',
    'changes.saved.successfully': 'Изменения сохранены успешно',
    'error.saving': 'Ошибка при сохранении',
    'error.loading.data': 'Ошибка загрузки данных',
    'error.label': 'Ошибка',
    'unknown.error.label': 'Неизвестная ошибка',
    'is.active': 'Активен',
    'loaded.label': 'Загружено',
    'manually.label': 'Вручную',
    'answer.label': 'Ответ',
    //
    'waiting.for.the.request.to.complete': 'Ожидание выполнения запроса...',
    'multi.email.field.autocomplete.placeholder': 'Введите E-mail адрес и нажмите Enter для подтверждения',
    'the.entered.email.address.has.already.been.added.to.the.list': 'Введенный E-mail адрес уже добавлен в список адресов',
    'file.download.failed': 'Ошибка скачивания файла',
    'only.english.letters.and.numbers.are.allowed': 'Доступны только буквы английского алфавита и цифры',
    'server.search.autocomplete.simple.new.value.add.prefix': 'Создать',
    'select.sector.label': 'Выберите сектор',
    'only.integers.are.allowed': 'Допустимы только целые числа',
    'only.latin.letters.are.allowed': 'Допустимы только латинские буквы',
    'only.latin.letters.and.numbers.are.allowed': 'Допустимы только латинские буквы и цифры',
    'entered.value.is.already.in.the.list': 'Указанное значение уже есть в списке',
    'confirm.the.value.by.pressing.enter': 'Подтвердите значение нажав Enter',
    'enter.values.and.press.enter.to.confirm': 'Введите значения. Подтверждение ввода - клавиша Enter.',
    'enter.time.in.format': `Введите время в формате '12:00', '1200' или '12'`,
    'enter.time.in.format.and.press.enter': `Введите время в формате '12:00', '1200' или '12' и подтвердите нажав Enter.`,
    // react-day-picker
    'move.focus.to.the.previous.week': 'Установить фокус на предыдущую неделю',
    'move.focus.to.the.next.day': 'Установить фокус на следующий день',
    'move.focus.to.the.next.week': 'Установить фокус на следующую неделю',
    'move.focus.to.the.previous.day': 'Установить фокус на предыдущий день',
    'move.focus.to.the.previous.month': 'Установить фокус на предыдущий месяц',
    'move.focus.to.the.next.month': 'Установить фокус на следующий месяц',
    'move.focus.to.the.previous.year': 'Установить фокус на предыдущий год',
    'move.focus.to.the.next.year': 'Установить фокус на следующий год',
    'move.focus.to.the.start.of.the.week': 'Установить фокус на начало недели',
    'move.focus.to.the.end.of.the.week': 'Установить фокус на конец недели',
    'select.the.focused.day': 'Выбрать день находящийся в фокусе',
    'date.rage.picker.shortcut.reset': 'Сбросить',
    'date.rage.picker.shortcut.previous.week': 'Пред. неделя',
    'date.rage.picker.shortcut.this.week': 'Эта неделя',
    'date.rage.picker.shortcut.last.week': 'Последняя неделя',
    'date.rage.picker.shortcut.next.week': 'След. неделя',
    'date.rage.picker.shortcut.previous.month': 'Пред месяц',
    'date.rage.picker.shortcut.current.month': 'Текущий месяц',
    'date.rage.picker.shortcut.last.month': 'Последний месяц',
    'date.rage.picker.shortcut.next.month': 'Сдед.месяц',
    // From useInfiniteScroll
    'refresh.updated.item.request.failed': 'Ошибка при обновлении измененной позиции в списке',
    // Validation
    'field.is.required.error': 'Обязательное поле',
    'field.{1}.is.required.error': 'Поле "{1}" является обязательным',
    'min.0.value.error': 'Значение должно быть целым неотрицательным числом',
    'positive.value.error': 'Значение должно быть целым положительным числом',
    'quantity.must.be.greater.than.or.equal.to.{1}': 'Количество должно быть большее или равно {1}',
    'quantity.must.be.less.than.or.equal.to.{1}': 'Количество должно быть меньше или равно {1}',
    'start.date.cannot.be.greater.than.end.date': 'Дата начала не может быть позднее даты окончания',
    'the.field.must.be.a.number': 'Только цифры',
    'the.field.must.be.a.whole.number': 'Только целочисленное значение',
    'the.field.{1}.must.be.a.number': 'Поле "{1}" может содержать только цифры',
    'the.field.{1}.must.be.an.integer.number': 'Поле "{1}" может содержать только целочисленное значение',
    'field.error.at.least.one.item.must.be.selected': 'Выберите хотя бы один элемент',
};
const shared = {
    'get.stock.list.request.failed': 'Ошибка загрузки списка стоков',
    'get.stock.info.request.failed': 'Ошибка загрузки данных стока',
};
const adm = {
    'orders.section': 'Заказы',
    'clients.section': 'Клиенты',
    'suppliers.section': 'Поставщики',
    'pricelists.section': 'Прайс листы',
    'reclamations.section': 'Рекламации',
    'reports.section': 'Отчёты',
    'reports-sup.section': 'По поставщикам',
    'reports-sup.breadcrumb': 'Отчёт по поставщикам',
    'reports-clients.section': 'По клиентам',
    'reports-payments.section': 'Календарь оплат',
    'settings.section': 'Настройки',
    'userprofile.section': 'Профиль пользователя',
    'role.editing.label': 'Редактирование роли',
    'role.creation.label': 'Создание роли',
    'user.editing.label': 'Редактирование данных пользователя',
    'user.creation.label': 'Создание пользователя',
    'cells.creation.label': 'Создание ячеек',
    // 'stock.editing.label': 'Редактирование стока',
    //'warehouse.creation.label': 'Создание склада',
    'pricelist.creation.label': 'Создание прайс-листа',
    'pricelist.editing.label': 'Редактирование прайс-листа',
    'customer.settings.label': 'Настройки клиента',
    'adding.rule.label': 'Добавление правила',
    'general.settings.section': 'Общие настройки',
    'general.settings.btn': 'Общие',
    'general.settings.desc': 'Просмотр и изменение данных о вашем магазине',
    'payments.settings.btn': 'Платежные системы',
    'payments.settings.desc': 'Управление платежами провайдерами',
    'returns.and.cancellations.label': 'Возвраты и отмена заказа',
    'returns.and.cancellations.desc': 'Настройки',
    'warehouses.label': 'Склады',
    'warehouses.settings.desc': 'Управление собственным наличием',
    'brands.settings.btn': 'Бренды и кроссы',
    'brands.settings.desc': 'Создание и управление брендами и артикулами',
    'notifications.settings.btn': 'Уведомления',
    'notifications.settings.desc': 'Настройка сообщений для вас и ваших клиентов',
    'warehouse.addresses.label': 'Складские адреса',
    'warehouse.addresses.settings.desc': 'Настройка и управление адресами ячеек',
    'users.settings.btn': 'Роли и пользователи',
    'users.settings.desc': 'Настройка и управление',
    // 'terms.settings.btn': 'Условия использования',
    'terms.settings.btn': 'Условия пользования',
    'terms.settings.desc': 'Юридические условия, оферта и политика возвратов',
    'terms.settings.fulldesc': `Это ваша оферта или договор, который принимает каждый клиент и посетитель вашего сайта. Вы сможете в любой момент времени изменить или дополнить данный текст.`,
    'language.settings.btn': 'Язык интерфейса',
    'language.settings.desc': 'Создание и управление языковыми локациями',
    // Suppliers.
    'supplier.logo': 'Лого поставщика',
    'generate.logo': 'Сгенерировать лого',
    'price.list.primary.data': 'Основные данные прайс-листа',
    'unique.logo.desc': `Уникальное лого прайс листа, будет отображаться в результатах поиска на сайте.`,
    'logo.is.busy.or.small': 'Лого занято или содержит менее 4 символов',
    'price.list.internal.name': 'Внутреннее наименование прайс листа, доступно только вам.',
    'price.list.name.placeholder': 'Мой первый прайс лист',
    'delivery.time.desc': 'Срок поставки в днях на центральный склад, данный срок поставки видят покупатели на сайте.',
    'price.list.currency': 'Валюта прайс листа',
    'exchange.rate': 'Курс обмена',
    'central.bank': 'Центральный банк',
    'central.bank.abbr': 'ЦБ',
    'own.label': 'Свой',
    'price.list.settings': 'Настройки прайс-листа',
    'price.download.method.desc': 'Способ загрузки прайс листа.',
    'price.file-encoding.label': 'Кодировка файла.',
    'price.file-encoding.desc': 'Кодировка файла.',
    'choose.pricelist.type': 'Выберете тип прайс листа.',
    'price.list.start': 'Начало прайс-листа',
    'price.list.start.desc': 'Номер строки, с которой начинается предложение в прайс листе.',
    'line.number.lower': 'номер строки',
    'delimiter.label': 'Разделитель',
    'tabulation.lower': 'табуляция',
    'another.lower': 'другой',
    'mail.for.orders.desc': 'Почта для получения заказов по текущему прайс листу.',
    'time.of-sending.desc': 'Время для получения заказов по мск.',
    'mail.address': 'Адрес почты',
    'mail.address.for.send': 'Укажите почту, с которой будете отправлять прайс лист.',
    'file.name.lg.desc': `Укажите точное по файла с расширением. Допускается использование символа “*” для обозначения изменяемой части в названии прайс листа. Например название прайс листа Price030317.xlsx, где 030317 обозначение даты (изменяемая часть), в этом случае стоит указать название Price*.xlsx.`,
    'letter.subject.desc': `Для корректной идентификации прайс листа, укажите тему письма. Допускается использование символа ”*” для обозначения изменяемой части темы письма. Например тема письма - Прайс лист от 03.03 наличие, то необходимо указать значение - Прайс лист от * наличие.`,
    'download.email.list.placeholder': 'прайс-лист для загрузки по почте',
    //"port.desc": `Укажите порт для подключения, если вы не понимаете о чем речь, попробуйте оставить текущее значение, или задайте вопрос вашему администратору.`,
    'price.list.column.numbers': 'Номера колонок прайс-листа',
    'price.list.column.numbers.desc': 'Укажите номера колонок в вашем прайс листе.',
    'partionism.label': 'Партионность',
    'partionism.tooltip.desc': 'Необязательный столбец. Позволяет настроить минимальное количество штук для продажи.',
    'fixed.brand': 'Фиксированный бренд',
    'brand.placeholder': 'Укажите бренд',
    'update.date': 'Дата обновления',
    'supplier.is.own.legal': 'Поставщик - собственное юр.лицо',
    'accept.returns': 'Принимает возвраты',
    'accounting.label': 'Проводки',
    'create.new.supplier.label': 'Регистрация нового поставщика',
    'supplier.has.been.created.successfully': 'Поставщик успешно создан',
    'supplier.has.been.edited.successfully': 'Данные поставщика успешно обновлены',
    'supplier.edit.request.failed': 'Ошибка обновления данных поставщика',
    'edit.supplier.pricelist.label': 'Редактировать прайс-лист поставщика',
    'choose.brands.and.articles.to.block': 'Выберите бренды и артикулы, которые хотите заблокировать.',
    'supplier.pricelist.edit.label': 'Редактирование прайс-листа поставщика',
    'supplier.pricelist.add.label': 'Создание прайс-листа поставщика',
    'supplier.pricelist.successfully.created': 'Прайс-лист успешно создан',
    'supplier.pricelist.successfully.edited': 'Прайс-лист успешно отредактирован',
    'error.while.creating.supplier.price.list': 'Ошибка при создании прайс-листа',
    'error.while.editing.supplier.price.list': 'Ошибка при сохранении прайс-листа',
    'invalid.price.list.logo.string': 'Неверное лого прайс-листа',
    'get.supplier.request.failed': 'Ошибка в получении данных поставщика',
    'get.currencies.request.failed': 'Ошибка в получении списка валют',
    'update.supplier.request.failed': 'Ошибка при сохранении данных поставщика',
    'check.logo.request.failed': 'Ошибка проверки лого поставщика',
    'get.available.logo.request.failed': 'Ошибка получения доступного лого поставщика',
    'get.supplier.pricelist.request.failed': 'Ошибка получения прайс-листа поставщика',
    'update.supplier.pricelist.request.failed': 'Ошибка при сохранении прайс-листа поставщика',
    'second.login.request.failed': 'Ошибка входа в панель поставщика',
    'file.has.not.been.uploaded': 'Файл не загружен',
    'blacklist.label': 'Чёрный список',
    'blacklist.edit.request.succeeded': 'Блэклист успешно обновлён',
    'blacklist.edit.request.failed': 'Ошибка обновления блэклиста',
    'get.blacklist.request.failed': 'Ошибка запроса получения блэклиста',
    // Price lists.
    'price.lists.label': 'Прайс-листы',
    'create.price.lists': 'Создайте прайс-листы',
    'format.label': 'Формат',
    'schedule.label': 'Расписание',
    'total.margin.caption': 'Общая наценка',
    'total.margin.comma.percent': 'Общая наценка, %',
    'markup.label': 'Наценка',
    'pricelists.and.margins.cp': 'Прайс-листы и их наценки, %',
    'updated.label': 'Обновлён',
    'generate.label': 'Сгенерировать',
    'is.being.generated': 'Генерируется',
    'match.label': 'Сопоставить',
    'unrecognized.stamps': 'Нераспознанные марки',
    'compare.stamps.with.our.db': 'Сопоставьте марки из вашего прайс-листа с марками из нашей базы',
    'not.recognized.brands': 'Не распознано брендов',
    'price.list.name': 'Название прайс-листа',
    'letter.subject': 'Тема письма',
    //"shipping.addresses": "Адреса для отправки",
    'sending.methods': 'Методы отправки',
    'emails.to.send': 'Адреса для отправки',
    'ftp.to.send': 'FTP для отправки',
    'ftp.label': 'FTP',
    'list.addresses.with.space': 'Перечислите адреса через пробел',
    'pack.to.archive': 'Упаковать в архив',
    'on.weekdays': 'По будням',
    'everyday.label': 'Каждый день',
    'how.often.send.orders': 'Как часто отправлять заказы',
    'list.hours.by.space': 'Перечислите часы через пробел',
    'placeholder.multiple.boxes.separated.by.space.label': 'Перечислите несколько почт через пробел',
    'ftp.connected.successfully': 'FTP успешно подключен',
    'directory.path': 'Путь к директории',
    'price.list.save.fail': 'Ошибка при сохранении прайс-листа',
    'price.list.has.been.saved.successfully': 'Прайс-лист успешно сохранён',
    'exclude.brands': 'Исключить бренды',
    'exclude.articles': 'Исключить артикулы',
    'encoding.label': 'Кодировка',
    'get.pricelist.request.failed': 'Ошибка загрузки прайс-листа',
    'update.pricelist.request.failed': 'Ошибка сохранения прайс-листа',
    'get.pricelist.prices.for.export.data.request.failed': 'Ошибка загрузки данных прайс-листов для экспорта',
    // Reclamations.
    // 'display.list': 'Списком',
    // 'display.by.cells': 'По ячейкам',
    // 'display.by.suppliers': 'По поставщикам',
    // 'display.by.counterparties': 'По контрагентам',
    'no.reclamations': 'Рекламаций нет',
    'create.a.return.task': 'Создание задания на возврат',
    // Stock
    'how.to.edit.value': 'Как изменить значение:',
    'specify.new.label': 'Указать новое',
    'increase.by.label': 'Увеличить на',
    'decrease.by.label': 'Уменьшить на',
    // 'new.neuter.label': 'Новое',
    'new.value.label': 'Новое значение',
    'total.value.for.all.items': 'Итоговое значение по всем обновляемым позициям:',
    'upload.prices.file.request.success': 'Файл с ценами успешно загружен',
    'upload.prices.file.request.failed': 'Ошибка загрузки файла',
    // General settings.
    'domain.desc.1': `Для добавления домена необходимо указать наши DNS записи в админ панели регистратора домена:`,
    'domain.desc.2': `После добавления записей укажите свой домен в поле и нажмите на кнопку "Проверить", если записи указаны верно, ваш домен успешно сохранится.`,
    'enter.domain.label': 'Укажите домен',
    'success.domain.check.response': 'Домен успешно добавлен',
    'fail.domain.check.response': 'Ошибка',
    'site.name.label': 'Название сайта', // to core?
    'appearance.label': 'Внешний вид',
    'appearance.desc': `Укажите название сайта или загрузите логотип. Выберите цветовую схему сайта.`,
    'color.scheme.label': 'Цветовая схема',
    'contacts.label': 'Контакты',
    'contact.details.label': 'Контактные данные',
    'contact.details.desc': `Для связи с вами вашими клиентами, поставщиками и администрацией портала.`,
    'customers.phone.label': 'Контактный телефон для клиентов',
    'inbox.mail.label': 'Почта для входящих сообщений',
    'outgoing.mail.label': 'Почта для исходящих сообщений',
    'from.customers.label': 'От клиентов',
    'from.administration.label': 'От администрации',
    'with.notifications.label': 'С уведомлениями',
    'with.mailing.orders.label': 'С рассылкой заказов',
    'service.settings.label': 'Настройки сервисов',
    'service.settings.desc': 'Почтовые адреса для основных сервисов системы. Без них система работать не будет.',
    'for.sup.pricelists.label': 'Для прайс-листов от поставщиков',
    'for.sup.invoices.label': 'Для инвойсов от поставщиков',
    'for.customers.orders.label': 'Для заказов от клиентов',
    'for.sup.answers.label': 'Для ответов от поставщиков',
    'success.email.check.response': 'Настройки корректны',
    'fail.email.check.response': 'Ошибка(необходим перевод)', // TODO: to delete ?
    'Login or password is wrong': 'Неверный логин или пароль',
    'client.types.label': 'Типы клиентов',
    'client.types.desc': 'Для формирования формы регистрации на вашем сайте, под все необходимые типы клиентов, с которыми вы работаете.',
    'legal.desc': 'Вы можете создать несколько юридических лиц для удобства работы с клиентами.',
    'add.legal.label': 'Добавить юр. лицо',
    'remove.legal.default.warning': 'Перед удалением данного Юр.лица назначьте другое по-умолчанию.',
    'managing.order.issuance.desc': 'Управление офисами выдачи заказов',
    // Roles and Users
    'u.sure.delete.role': 'Вы уверены, что хотите удалить роль',
    'u.sure.delete.user': 'Вы уверены, что хотите удалить пользователя',
    'password.reset.confirmation': 'Подтверждение сброса пароля',
    'u.sure.reset.user.password': 'Вы уверены, что хотите сбросить пароль для пользователя',
    'u.sure.unblock.user': 'Вы уверены, что хотите разблокировать пользователя',
    'username.label': 'по пользователя',
    'unlock.in.minutes': 'Разблокировать через (минут)',
    'user.activation': 'Активация пользователя',
    'user.lockout': 'Блокировка пользователя',
    'users.label': 'Пользователи',
    'create.user': 'Создать пользователя',
    'create.role': 'Создать роль',
    'enter.role.name': 'Укажите название роли',
    'enter.role.name.desc': 'Оно будет отображаться при идентификации и при создании пользователей',
    'choose.accesses': 'Выберите доступы',
    'choose.one.several.all': 'Можно выбрать один, несколько или все.',
    'no.accesses.available': 'Нет доступных доступов',
    'primary.data.desc': `Для создания пользователя необходимы его ФИО, дата рождения и адрес электронной почты (на который будет отправлен пароль)`,
    'choose.role.label': 'Выберите роль',
    'choose.role.desc': `Выберете роль или несколько. От выбора будет зависеть доступ к разным приложениям. Так, например, роль Кладовщик имеет доступ только к WMS, пользователи с данной ролью не смогут зайти в админ панель.`,
    'upload.photo': 'Загрузите фото',
    'upload.user.photo.desc': 'Можно загрузить реальное фото сотрудника или аватар',
    'user.comment.desc': 'Здесь вы можете записать любую дополнительную информацию о пользователе',
    'article.search.results': 'Результаты поиска артикула',
    'select.delivery.points': 'Выберите пункты выдачи...',
    'user.password.reset.successfully': 'Пароль пользователя успешно сброшен',
    'user.password.reset.fail': 'Ошибка сброса пароля',
    // Settings -> Storage, Stock
    'no.sectors.created.yet': 'Пока не создано ни одного сектора',
    'deleting.sector.not.possible': 'Удаление сектора невозможно, если в нём присутствует сток.',
    'u.sure.delete.sector': 'Вы уверены, что хотите удалить сектор',
    'specify.type': 'Укажите тип',
    'stock.type.desc': 'Сток - ваше собственное наличие',
    'transfer.type.desc': 'Трансфер - товар находящийся на хранении, пока не принятый для продажи',
    'crossdocking.type.desc': 'Кросс-докинг - транзитный склад',
    'stock.cells': 'Ячейки стока',
    'crossdocking.cells': 'Ячейки кросс-докинга',
    'transfer.cells': 'Ячейки трансфера',
    'storage.primary.data.desc': `Выберете сектор, укажите диапазон ячеек, можно указать вручную или с помощью сетки. Для этого зажмите левую кнопку мыши в ячейке и выделите небходимое кол-во, просто, как в эксель. По горизонтали кол-во ячеек, по вертикали кол-во этажей.`,
    'create.warehouse': 'Создать склад',
    'choose.stock.desc': 'Выберите Сток для хранения и продажи товара',
    'choose.transfer.desc': 'Выберите Трансфер, если ваш товар находится в пути или еще не принят к продаже',
    'create.new.stock': 'Создать новый сток',
    'create.new.crossdocking': 'Создать новый кроссдокинг',
    'create.new.transfer': 'Создать новый трансфер',
    'come.up.unique.stock.name': `Придумайте уникальное название для стока и выберите адреса на складе, в которых будет храниться товар.`,
    'latin.letters.only.translit': 'Tolko latinskie bukvi',
    'cell.range': 'Диапазон ячеек',
    'add.range': 'Добавить диапазон',
    'price.generation.label': 'Генерация прайса',
    'price.generation.desc': `Укажите FTP для генерации прайса. Товары со стока будут обновляться 1 раз в минуту и складываться в виде файла на FTP`,
    'ftp.address': 'Адрес FTP',
    'file.type': 'Тип файла',
    'no.addresses.created.for.stocktype': 'Для {1}ов не создано ни одного адреса.',
    // Settings -> Roles
    'get.claims.request.failed': 'Ошибка запроса клэймов',
    // Payments
    'payment.method.connected.successfully': 'Способ оплаты успешно подключен',
    'you.can.find.info.on.official': 'Ознакомиться с тарифами и услугами можно на официальном сайте',
    'following.payments.available': 'После активации будут доступны способы оплаты',
    'payments.available.desc': 'Карты (VISA, Mastercard, Maestro), Сбербанк Онлайн, Qiwi-кошелёк, Yandex.Деньги',
    'gateway.name': 'по шлюза',
    'gateway.password': 'Пароль шлюза',
    'paypal.info': 'C PayPal вы можете отправлять и получать денежные платежи',
    'paypal.info.desc': `PayPal - крупнейшая дебетовая электронная платёжная система. Позволяет клиентам оплачивать счета и покупки, отправлять и принимать денежные переводы.`,
    'bank.transfer': 'Банковский перевод',
    'legal.account.transfer.label': 'Перевод на расчётный счёт юридического лица',
    'legal.account.transfer.desc': `В качестве получателя будет представляться Юр. лицо, которое вы выбрали в карточке клиента или Юр. лицо, указанное по умолчанию на странице настоек. Клиент сможет скачать счёт в формате PDF.`,
    'cash.payment': 'Оплата наличными',
    'cash.payment.store.or.courier': 'Оплата наличными в магазине или курьеру',
    'cash.payment.store.or.courier.desc': 'Данная опция является информационной и не требует интеграции.',
    'no.reports.found': 'Отчеты не найдены',
    'calendar.label': 'Календарь',
    'list.label': 'Список',
    'send.CRF.receipt.info': 'отправлять информацию для чеков ОФД',
    // Orders
    'order.filters': 'Фильтры заказов',
    'clear.order.filters': 'Сброс всех фильтров',
    'orders.id.label': 'ID заказов',
    'vendors.code': 'Артикулы',
    'number.of.items.sold.pieces': 'Кол-во проданого, шт.',
    'apply.label': 'Применить',
    'show-up.label': 'Показать',
    'statistics.label': 'Статистика',
    'statistics.for.a.period.label': 'Статистика за период',
    'delivery.date': 'Дата поставки',
    // 'change.status': 'Смена статуса',
    'order.portion.status': 'Статус позиции заказа',
    'current.status': 'Текущий статус',
    'change.status.to': 'Сменить статус на:',
    'line.are.loaded.by': 'Строк подгружается по',
    'lines.label': 'Строк',
    'view.order.status.history.label': 'История',
    'get.order.request.failed': 'Ошибка при получении данных Заказа',
    'order.comment.update.request.failed': 'Ошибка обновления комментария к Заказу',
    'order.status.update.request.failed': 'Ошибка обновления статуса Заказа',
    'get.order.portion.states.request.failed': 'Ошибка получения списка статусов заказов',
    'get.order.status.history.request.failed': 'Ошибка получения истории заказа',
    // Order portion status
    'unknown.status.label': 'Неизвестный статус',
    'ordered.label': 'Заказан',
    'sent.to.supplier': 'Отправлен поставщику',
    'sent.to.supplier.short': 'Отправлен пост',
    'confirmed.by.supplier': 'Подтвержден поставщиком',
    'confirmed.by.supplier.short': 'Подтвержден пост',
    'sent.to.customer': 'Отправлен заказчику',
    'sent.to.customer.short': 'Отправлен зак',
    'received.label': 'Получен',
    'canceled.by.supplier': 'Отменен поставщиком',
    'canceled.by.supplier.short': 'Отменен пост',
    'canceled.by.administrator': 'Отменен администратором',
    'canceled.by.administrator.short': 'Отменен aдм',
    'canceled.by.customer': 'Отменен заказчиком',
    'canceled.by.customer.short': 'Отменен зак',
    'in.the.stock.{1}': 'На складе {1}',
    'in.the.stock.short': 'На складе',
    'stay.at.supplier': 'У поставщика',
    'in.transit': 'В пути',
    'ready.to.issue': 'Готов к выдаче',
    'refund.approved.and.expected.in.warehouse': 'Возврат одобрен, ожидается на складе',
    'refund.approved.and.expected.in.warehouse.short': 'Возврат одобрен',
    'accepted.at.OPP': 'Принят в ПВЗ',
    'transferred.to.delivery.service': 'Передан в службу доставки',
    'transferred.to.delivery.service.short': 'Доставка',
    'transferred.to.delivery.service.own': 'Передан в службу доставки (собств.)',
    'transferred.to.delivery.service.own.short': 'Доставка (соб)',
    // Customers
    'edit.client.settings': 'Редактировать настройки клиента',
    'internal.name': 'Внутреннее наименование',
    'registration.date': 'Дата регистрации',
    'balance.and.limit': 'Баланс/Лимит',
    'enter.to.the.site': 'Вход на сайт',
    'guest.mark-up': 'Гостевая наценка',
    'name.of.the.price.list': 'Наименование прайс листа',
    'personal.margin': 'Персональная наценка',
    'add.personal.margin': 'Назначить персональную наценку',
    'connected.to.the.site': 'Подключен к сайту',
    'connected.to.the.client': 'Подключен к клиенту',
    'personal.mark-up.on.the.price.list': 'Персональная наценка на прайс-лист',
    'orders.from.file': 'Заказы из файла',
    'add.rule.label': 'Добавить правило',
    'update.customer.notify.schedule.request.failed': 'Ошибка обновления расписания уведомлений',
    'email.and.sms.notification.schedule': 'Расписание уведомлений по эл.почте и смс',
    'notification.schedule': 'Расписание отправки уведомлений',
    'notification.schedule.has.been.successfully.updated': 'Расписание уведомлений обновлено',
    'customer.email.has.been.successfully.deleted': 'Электронная почта удалена',
    'beginning.of.file.nine.column.label': 'Начало файла',
    'price.keyword.nine.column.label': 'ID',
    'email.for.send': 'Почта для отправки',
    'email.for.reply': 'Почта для ответа',
    'no.email.addresses.yet': 'Email адреса отсутствуют',
    'no.delivery.addresses.added': 'Адреса доставки отсутствуют',
    'edit.customer.delivery.address.request.failed': 'Ошибка обновления данных адреса доставки',
    'add.customer.delivery.address.request.failed': 'Ошибка создания адреса доставки',
    'no.legals.added': 'Юридические лица отсутствуют',
    'edit.customer.legal.request.failed': 'Ошибка обновления данных компании клиента',
    'add.customer.legal.request.failed': 'Ошибка создания компании клиента',
    'deleting.email.header': 'Удаление Email адреса',
    'email.{1}.will.be.removed.confirmation.text': 'Email "{1}" будет удалён без возможности восстановления.',
    'delete.customer.email.request.failed': 'Ошибка удаления Email-а клиента',
    'edit.customer.email.request.failed': 'Ошибка редактирования Email-а клиента',
    'column.settings': 'Настройки колонок',
    'order.settings': 'Настройки заказов',
    'exact.match': 'точное совпадение',
    'digression.in.percent': 'отклонение в %',
    'to.order.label': 'Заказать',
    'total.quantity.from.order': 'Полное кол-во из заказа',
    'partial.quantity': 'частичное кол-во',
    'price.lists.for.order': 'Прайс-листы для заказа',
    'mark-up.on.price.lists': 'Наценка на прайс-листы, %',
    'delete.rule.label': 'Удалить правило?',
    'question.delete.label': 'Удалить?',
    'add.id.label': 'Добавить ID',
    'get.customer.request.failed': 'Ошибка загрузки данных клиента',
    'recharge.balance.request.failed': 'Ошибка пополнения баланса клиента',
    'payment.on.behalf.of.the.company': 'Оплата от лица компании',
    'payment.from.the.company.{1}': 'Оплата от компании "{1}"',
    // Language
    'create.language': 'Добавить язык',
    'edit.language': 'Редактировать язык',
    'language.name.label': 'Название',
    'active.label': 'Активен',
    'payment.deferment.comma.days': 'Отсрочка платежа, дн',
    'trade.on.behalf.of': 'Торгуем от',
    'minimum.shipment.amount': 'Минимальная сумма отгрузки',
    'shipment.schedule': 'График отгрузок',
    // Delivery point
    'create.delivery.point': 'Создать ПВЗ',
    'edit.delivery.point': 'Редактировать ПВЗ',
    'not.created.delivery.point': 'Вы пока не создали ни одного ПВЗ',
    'own.delivery.point': 'Собственные ПВЗ',
    'partnership.delivery.point': 'Партнерские ПВЗ',
    'delivery.type.label': 'Тип ПВЗ',
    'delivery.type.dis': 'Выберите какой ПВЗ вы хотитете создать',
    'partnership.label': 'Партнерский',
    'own.delivery.label': 'Собственный',
    'qty.delivery.label': 'Кол-во ПВЗ',
    'name.delivery.label': 'Название ПВЗ',
    'delivery.name.dis': 'Внутреннее наименование, доступно только Вам',
    'delivery.contact.information.dis': 'Укажите адрес ПВЗ, контактные данные и график работы.',
    'cost.of.delivery.label': 'Стоимость доставки',
    'delivery.set.delivery.cost.dis': 'Настройте стоимость доставки в этот ПВЗ.',
    'fixed.cost.label': 'Фиксированная стоимость',
    'mark-up.from.order.amount.label': '% от суммы заказа',
    'order.amount.less.than.label': 'сумма заказа меньше чем',
    'if.label': 'если',
    'delivery.point.successfully.connected': 'ПВЗ успешно подключен',
    'max.price': 'Цены дороже чем',
    'min.price': 'Цены дешевле чем',
    //days name
    'mo.label': 'ПН',
    'tu.label': 'ВТ',
    'we.label': 'СР',
    'th.label': 'ЧТ',
    'fr.label': 'ПТ',
    'sa.label': 'СБ',
    'su.label': 'ВС',
    'dayOff.label': 'Выходной',
    // Breadcrumbs
    // 'breadcrumb.customer.personal.markup': 'Персональная наценка клиента',
    // 'breadcrumb.customer.orders.from.file': 'Заказы из файла клиента',
    'copied.label': 'Скопировано',
    'copy.to.clipboard': 'Копировать в буфер обмена',
    'unknown.file.type': 'Неизвестен',
    //'order.import.rule.deleting.title': 'Удаление правила импорта заказа',
    'deletion.confirmation': 'Подтверждение удаления',
    'rule.edit.general.section.label': 'Основное',
    'are.you.sure.you.want.to.delete.order.import.rule': 'Вы уверены, что хотите удалить правило?',
    // 'add.new.order.import.rule': 'Добавление нового правила',
    'add.new.order.import.rule': 'Новое правило',
    'edit.order.import.rule': 'Редактирование правила импорта заказа',
    'order.import.rule.added.successfully': 'Правило импорта заказа успешно создано',
    'order.import.rule.updated.successfully': 'Правило импорта заказа успешно обновлено',
    'order.import.rule.update.failed': 'Ошибка сохранения правила импорта заказа',
    'unconfirmed.email.title': 'Неподтверждённый email',
    'unconfirmed.schedule.time': 'Неподтверждённое значение времени',
    'proceed.button.label': 'Продолжить',
    'field.1.contains.unconfirmed.email.2': 'Поле "{1}" содержит неподтвержденный email. Нажав кнопку продолжить email "{2}" не будет сохранён ',
    'field.1.contains.unconfirmed.time.value.2': 'Поле "{1}" содержит неподтвержденное значение времени. Нажав кнопку продолжить время "{2}" не будет сохранёно ',
    'statuses.label': 'Статусы',
    'brands.label': 'Бренды',
    'order.ids.label': 'Идентификаторы заказов',
    'press.escape.to.cancel.changes': 'Для отмены нажмите Esc',
    'update.comment': 'Редактирование комментария',
    'create.supplier': 'Создать поставщика',
    'file.encoding': 'Кодировка файла',
    'please.enter.the.first.two.letters.to.search': 'Введите первые две буквы для поиска',
    'delete.all': 'Удалить все',
    'unrecognized.brands.dialog.title': 'Нераспознанные марки',
    'brands.has.been.linked.successfully': 'Бренды успешно сопоставлены',
    'link.unrecognized.brands.request.failed': 'Ошибка сопоставления брендов',
    'create.pricelist': 'Создать прайс-лист',
    'at.for.time.preposition': 'в',
    'invalid.email.address': 'Неверный email адрес',
    'invalid.tcp.ip.port': 'Неверный порт',
    'ftp.connection.failed': 'Ошибка подключения к FTP',
    'are.you.sure.delete.pricelist': 'Вы уверены, что хотите удалить прайс-лист',
    'file.name.orders': 'заказы',
    'protocol.type.label': 'Протокол загрузки',
    'server.address.label': 'Адрес сервера',
    'path.to.file.label': 'Путь к файлу',
    'file.parsing.schedule.label': 'Время парсинга файла',
    'server.error': 'Ошибка сервера',
    'internal.server.error': 'Внутренняя ошибка сервера',
    'percent.label': 'процент',
    'file.download.check.header': 'Проверка загрузки файла',
    'file.size.limit.is.{1}.mb': 'Максимально допустимый размер файла {1} Mb.',
    'file.uploading.caption': 'Отправка файла... ',
    'group.by.cells.label': 'группировать по ячейкам',
    'all.cells.label': 'Все ячейки',
    'file.name.stock': 'Сток',
    'back.to.stock.list.label': 'Назад к складам',
    'updated.positions.count': 'Количество обновляемых позиций в списке:',
    'relocate.position.dialog.header.label': 'Перемещение позиции',
    'current.stock.label': 'Текущее находждение позиции: ',
    'the stock into which the position will be moved': 'Сток на который позиция будет перемещена',
    'the cell into which the position will be moved': 'Ячейка в которую позиция будет перемещена',
    'position.units.quantity.to.move': 'Количество единиц позиции для перемещения',
    'relocate.enable.only.for.single.position': 'Доступно только для одной выбранной позиции',
    'quantity.change.enable.when.stock.display.type.is.grouped': 'Доступно только при группировке по ячейкам',
    'inactive.stock.balance.items.label': 'Отключенные',
    'stock.balance.deactivation.warning.header': 'Отключение позиций',
    'u.sure.u.want.to.deactivate.selected.positions.message': 'Вы уверены, что хотите отключить выбранные позиции?',
    'u.sure.u.want.to.delete.selected.positions.message': 'Вы уверены, что хотите удалить выбранные позиции?',
    'stock.balance.deactivate.label': 'Отключить',
    'upload.prices.btn': 'Загрузить цены',
    'please.choose.cell': 'Б',
    'customer.creation.label': 'Регистрация нового клиента',
    'back.to.customers.list.label': 'Назад к Клиентам',
    'back.to.customer.settings.list.label': 'Назад к настройкам Клиента',
    'manual.upload.file.error.label': 'Ошибка отправки файла',
    'file.processing.error.label': 'Ошибка загрузки файла',
    // 'file.has.been.deleted': 'Файл удалён',
    'no.file.uploaded': 'Файл не загружен',
    'file.has.been.selected.press.upload.button': 'Файл выбран. Теперь нажмите "Загрузить"',
    'select.file.to.be.uploaded': 'Выберите файл для загрузки',
    'brands.mapping.will.be.available.after.full.price.list.changes.is.accepted': 'Сопоставление брэндов станет доступно после подтверждения всех изменений прайс-листа',
    'stock.list.is.empty.in.select.component.warning': 'Список стоков пуст!',
    'back.to.settings.list.label': 'Назад к Настройкам',
    'back.to.roles.label': 'Назад к Ролям',
    'back.to.users.label': 'Назад к Пользователям',
    'activate.user.question': 'Активировать пользователя?',
    'the.user.1.will.be.able.to.act.after.activation': 'После активации пользователь {1} снова сможет успешно проходить авторизацию и вносить изменения на сайте',
    'activate.user.label': 'Активировать пользователя',
    'activate.label': 'Активировать',
    // 'deactivate.user.label': 'Заблокировать пользователя',
    'deactivate.user.label': 'Деактивировать пользователя',
    // 'auto.activate.user.caption': 'Разблокировать через указанное количество минут',
    'auto.activate.user.caption': 'Активировать через указанное количество минут',
    // 'user.deactivation.warning.text.first.part': 'Пользователь',
    'user.deactivation.warning.text': 'Пользователь будет заблокирован, что приведет к его деавторизации и невозможности последующей авторизации и работы на сайте до тех пор, пока он будет находиться в заблокированном состоянии.',
    'user.{1}.will.be.removed.confirmation.text': 'Пользователь "{1}" будет безвозвратно удалён.',
    'user.edit.main.info.description.header': 'Основные данные',
    'user.edit.main.info.description.info': 'Персональные данные пользователя и роли',
    'user.edit.reset.password.description.header': 'Сброс пароля',
    'user.edit.reset.password.description.info': 'После сброса пароля пользователю будет необходимо создать новый пароль для входа в систему',
    'user.edit.delete.description.header': 'Удаление пользователя',
    'user.edit.delete.description.info': 'Пользователь будет удалён без возможности восстановления',
    'user.edit.deactivate.description.header': 'Деактивация',
    'user.edit.deactivate.description.info': 'Предотвращает возможность авторизации пользователя и внесения им каких-либо изменений на сайте',
    'delete.user.question.caption': 'Удалить пользователя?',
    'delete.user.avatar.question': 'Удалить фото?',
    'delete.user.avatar.caption': 'Удалить фото',
    'reset.user.password.question': 'Сбросить пароль?',
    'reset.user.password.info': 'После сброса пароля пользователю {1} будет необходимо создать новый пароль для успершного входа в систему',
    'change.avatar.caption': 'Изменить фото',
    'avatar.will.be.deleted.after.entire.user.edit.is.finished': 'Фото пользователя будет удалено после подтверждения всех изменений сделанных для пользователя',
    'user.{1}.will.be.permanently.deleted': 'Пользователь {1} будет удалён без возможности восстановления',
    'delete.user.label': 'Удалить пользователя',
    'choose.proper.birthday.date': 'Укажите верную дату рождения',
    'user.notes.label': 'Дополнительная информация о пользователе',
    'delete.role.label': 'Удалить роль',
    'delete.role.question.caption': 'Удалить роль?',
    'role.{1}.will.be.unassign.from.users': 'Роль "{1}" будет удалена без возможности восстановления. Удалённая роль будет снята у всех пользователей, кому она назначена.',
    'the.user.does.not.exist': 'Пользователя не существует',
    'the.role.does.not.exist': 'Роль не существует',
    'access.levels': 'Уровни доступа',
    'role.edit.main.info.description.header': 'Основные данные',
    'role.edit.main.info.description.info': 'Укажите название роли и соответствующие уровни доступа',
    'role.edit.delete.role.description.header': 'Удаление роли',
    'role.edit.delete.role.description.info': 'Роль будет удалена без возможности восстановления. Удалённая роль будет снята у всех пользователей, кому она назначена.',
    'general.sidebar.section': 'Основное',
    'stock.edit.breadcrumb.label': 'Редактирование склада',
    'stock.add.breadcrumb.label': 'Создание нового склада',
    'new.stock': 'Новый склад',
    'delete.stock.label': 'Удалить сток',
    'remove.range.label': 'Удалить диапазон',
    'stock.edit.main.info.description.header': 'Основные данные',
    'stock.edit.main.info.description.info': "В поле 'Тип' указывается Сток для хранения и продажи товара или Трансфер, если товар находится в пути или еще не принят к продаже",
    'stock.edit.delete.description.header': 'Удаление склада',
    'stock.edit.delete.description.info': 'Склад будет удален без возможности восстановления. Ячейки занятые под склад освободятся и попадут в пул свободных ячеек.',
    'delete.stock.question.caption': 'Удалить склад?',
    'stock.{1}.will.be.permanently.deleted': 'Склад {1} будет удалён без возможности восстановления.',
    'the.stock.does.not.exist': 'Склад не существует',
    'the.storage.sector.does.not.exist': 'Сектор хранилища не существует',
    'please.select.cell': 'Укажите ячейку',
    'start.cell.cannot.follow.end.cell.error.label': 'Начальная ячейка диапазона не может идти после конечной',
    'end.cell.cannot.go.before.start.cell.error.label': 'Конечная ячейка диапазони не может идти до начальной',
    'storage.add.sector.label': 'Создание сектора',
    'storage.edit.sector.label': 'Редактирование сектора',
    'storage.view.sector.label': 'Просмотр сектора',
    'new.storage.sector.label': 'Новый сектор',
    'storage.sector.add.breadcrumb.label': 'Создание нового сектора',
    'back.to.storage.label': 'Назад к Складским адресам',
    'storage.sector.edit.description.part.1': 'Выберите тип стока и букву сектора.',
    'storage.sector.edit.description.part.2': 'Укажите диапазон ячеек, которые будут составлять область хранения. Диапазон указывается вручную с помощью полей "Этаж" и "Ячейки", либо выбором области на карте сектора с помощью мыши и кнопок "Добавить" и "Удалить".',
    'storage.sector.edit.description.part.3': 'Для выбора области зажмите левую кнопку мыши в ячейке и перемещайте мышь выделяя небходимое кол-во ячеек, просто, как в Excel. По горизонтали - ячейки, по вертикали - этажи. После того как область выбрана нажмите "Добавить" или "Удалить"',
    'storage.sector.range.selection.header': 'Выбор области хранения',
    'storage.sector.range.selection.manual': 'Подсказка',
    'letter.label': 'Буква',
    'total.cells.label.colon': 'Всего ячеек:',
    'total.cells.label': 'Всего ячеек',
    'occupied.cells.label': 'Занято ячеек',
    'free.cells.label': 'Свободно ячеек',
    'create.sector': 'Создать сектор',
    'delete.storage.sector.question.caption': 'Удалить сектор?',
    'storage.sector.{1}.will.be.permanently.deleted': 'Сектор {1} будет удалён без возможности восстановления.',
    'delete.sector.label': 'Удалить сектор',
    'previous.pagination.label': 'Предыдущий',
    'next.pagination.label': 'Следующий',
    'nothing.is.selected.label': 'Ничего не выбрано',
    'not.occupied.area.label': 'Не занятая область хранения',
    'cells.occupied.{1}.label': 'Занято ячеек: {1}',
    'cells.free.{1}.label': 'Свободно ячеек: {1}',
    'not.occupied.legend.label': 'Свободно',
    'occupied.legend.label': 'Занято под сток',
    'manager.label': 'Менеджер',
    'invalid.date.error.message': 'Неверная дата',
    'create.customer': 'Создать клиента',
    'add.order.comment': 'Добавить комментарий к заказу',
    'admin.claim.label': 'Абсолютная власть',
    'customer.managers.label': 'Менеджеры клиентов',
    'supplier.managers.label': 'Менеджеры поставщиков',
    'positions.(count).label': 'Позиций',
    'see.console.for.more.info': 'Дополнительные сведения об ошибке показаны в консоле.',
    'user.activated.successfully': 'Пользователь успешно активирован',
    'user.activation.failed': 'Ошибка активации пользователя',
    'user.deactivated.successfully': 'Пользователь успешно деактивирован',
    'user.deactivation.failed': 'Ошибка деактивации пользователя',
    'user.deleted.successfully': 'Пользователь успешно удалён',
    'user.deletion.failed': 'Ошибка удаления пользователя',
    'password.reset.successfully': 'Пароль пользователя успешно сброшен',
    'password.reset.failed': 'Ошибка сброса пароля',
    'user.updated.successfully': 'Пользователь успешно обновлён',
    'user.added.successfully': 'Пользователь успешно добавлен',
    'user.update.failed': 'Обшибка при сохранении пользователя',
    'role.updated.successfully': 'Роль успешно обновлена',
    'role.added.successfully': 'Роль успешно добавлена',
    'role.update.failed': 'Обшибка при сохранении роли',
    'role.deleted.successfully': 'Роль успешно удалена',
    'role.deletion.failed': 'Ошибка удаления роли',
    'sector.deleted.successfully': 'Сектор успешно удалён',
    'sector.deletion.failed': 'Ошибка удаления сектора',
    'sector.updated.successfully': 'Сектор успешно обновлён',
    'sector.added.successfully': 'Сектор успешно добавлен',
    'sector.update.failed': 'Обшибка при сохранении сектора',
    'stock.deleted.successfully': 'Склад успешно удалён',
    'stock.deletion.failed': 'Обшибка удаления склада',
    'stock.updated.successfully': 'Склад успешно обновлён',
    'stock.added.successfully': 'Склад успешно добавлен',
    'stock.update.failed': 'Обшибка при сохранении склада',
    'authorization.error': 'Ошибка авторизации',
    'customer.activation.failed': 'Ошибка активации клиента',
    'customer.details.updated.successfully': 'Данные клиента успешно обновлены',
    'customer.details.update.failed': 'Обшибка обновления данных клиента',
    'new.customer.added.successfully': 'Новый клиент успешно создан',
    'new.customer.create.failed': 'Обшибка создания нового клиента',
    'order.import.rule.activation.failed': 'Ошибка активации правила отправки',
    'connect.pricelist.to.client.failed': 'Ошибка подключения прайс-листа к клиенту',
    'pricelist.activation.failed': 'Ошибка активации прайс-листа',
    'pricelist.deleted.successfully': 'Прайс-лист успешно удалён',
    'pricelist.deletion.failed': 'Ошибка удаления прайс-листа',
    'stock.cell.balance.activation.failed': 'Ошибка выключения позиций',
    'stock.cell.balance.deleted.successfully': 'Позиции успешно удалены',
    'stock.cell.balance.deletion.failed': 'Ошибка удаления позиций',
    'stock.cell.balance.margin.updated.successfully': 'Маржа успешно изменена',
    'stock.cell.balance.margin.update.failed': 'Ошибка изменения маржи позиций',
    'stock.cell.balance.cost.updated.successfully': 'Цена успешно изменена',
    'stock.cell.balance.cost.update.failed': 'Ошибка изменения цены позиций',
    'stock.cell.balance.quantity.updated.successfully': 'Количество успешно изменено',
    'stock.cell.balance.quantity.update.failed': 'Ошибка изменения количества',
    'stock.cell.balance.item.relocated.successfully': 'Единицы позиции перемещены успешно',
    'stock.cell.balance.item.relocate.failed': 'Ошибка перемещения единиц позиции',
    'stock.cell.balance.item.created.successfully': 'Позиция успешно добавлена',
    'stock.cell.balance.item.create.failed': 'Ошибка добавления позиции',
    'supplier.price.list.activation.failed': 'Ошибка активации прайс-листа',
    'for.today': 'За сегодня',
    'last.7.days': 'За последние 7 дней',
    'last.30.days': 'За последние 30 дней',
    'last.year': 'За последний год',
    'for.the.specified.period': 'За указаный период',
};
const main = {
    'our.results.label': 'Наш бизнес в цифрах',
    'maintenance.parts.label': 'Подбор запчастей для ТО',
    'parts.quantity.label': 'Запчастей онлайн',
    'orders.today.label': 'Заказов сегодня',
    'search.queries.label': 'Поисковых запроса',
    'enter.phone.email.desc': 'Введите номер телефона или email, указанный при регистрации',
    'phone.or.email.label': 'Номер телефона или email',
    'recipient.fullName.label': 'ФИО получателя',
    'personal.area.label': 'Личный кабинет',
    'enter.detail.number': 'Введите номер детали',
    'for.suppliers.label': 'Поставщикам',
    'refund.policy': 'Политика возвратов',
    'refund.policy.desc': `Отдельная часть оферты касательно политики возвратов. Вынесена в отдельный блок специально для того, чтобы ваши клиенты могли ознакомиться с таким важным аспектом сотрудничества.`,
    'contact.us.label': 'Написать нам',
    'cart.label': 'Корзина',
    'cart.is.empty': 'Корзина пуста',
    'total.items': 'Всего позиций',
    'total.cost': 'Общая стоимость',
    'goto.checkout': 'Перейти к оформлению',
    'yout.cart.empty.now': 'Ваша корзина сейчас пуста',
    'use.search.to.find': 'Воспользуйтесь поиском, чтобы найти интересующие вас товары',
    'adding.item.to.cart': 'Добавление товара в корзину',
    'add.to.cart': 'В корзину',
    'total.items.in.your.cart': 'Всего товаров в вашей корзине',
    'create.return.request': 'Создание запроса на возврат',
    'to.place.an.order.pay': 'Для оформления заказа оплатите',
    'to.place.an.order.pay.extra': 'Для оформления заказа доплатите',
    'continue.shopping': 'Продолжить покупки',
    'order.successfully.placed': 'Заказ успешно оформлен',
    'monitor.order.status.left-part': 'В разделе ',
    'monitor.order.status.right-part': ' можно следить за его состоянием',
    'pay.on.credit': 'Оплатить в кредит',
    'write.off.from.balance': 'списать с баланса',
    'pay.with.balance': 'Оплатить с баланса',
    'you.can.use.credit.limit': 'Вы можете оплатить заказ используя персональный кредитный лимит.',
    'not.enough.money.to.pay': 'Для оплаты заказа на Вашем счёте не хватает денежных средств.',
    'order.will.go.to.work.instantly': 'Заказ уйдёт в работу мгновенно',
    'order.will.go.to.work.after': 'Заказ уйдёт в работу только после получения средств',
    'price.may.change.during.this.time': 'Цена может измениться за это время',
    'bank.card': 'Банковская карта',
    'yandex.money': 'Яндекс.деньги',
    'from.legal.entity.account': 'С рассчётного счёта Юр.лица',
    'you.can.pay.in.cash': 'Вы можете оплатить заказ наличными в магазине',
    'current.balance.label': 'Текущий остаток',
    'recharge.label': 'Пополнить',
    'recharge.balance': 'Пополнить баланс',
    'balance.recharge': 'Пополнение баланса',
    'credit.limit.label': 'Кредитный лимит',
    'accounting.for.label': 'Проводки за',
    'enter.amount': 'Введите сумму',
    'order.payment.label': 'Оплата заказов',
    'tracking.number.abbr': 'Трэкинг №',
    'track.order': 'Отследить заказ',
    'receive.sms.notifications': 'Получать уведомления по SMS',
    'receive.email.notifications': 'Получать уведомления по эл.почте',
    'your.message.has.been.sent': 'Ваше сообщение отправлено',
    'we.will.answer.you.soon': 'Постараемся ответить вам в кратчайшие сроки',
    'contact.us': 'Напишите нам',
    'you.can.write.any.question': `Вы можете написать нам по любому вопросу. Постараемся ответить вам в кратчайшие сроки.`,
    'your.return.request.will.be.sent': 'Ваш запрос на возврат {1} менеджерам {2}. Среднее время рассмотрения 3-5 рабочих дней.',
    'will.be.sent': 'будет отправлен',
    'has.been.sent': 'отправлен',
    'order.has.been.canceled': 'Заказ № {1} отменен',
    'funds.credited.to.balance': 'Средства зачислены на Ваш баланс',
    'refund.approved': 'Возврат одобрен',
    'return.accepted.balance.replenished': 'Ваш возврат принят на складе, баланс вашего аккаунта пополнен',
    'refund.accepted.in.stock': 'Возврат принят на складе',
    'refund.accepted.in.stock.short': 'Возврат принят',
    'refund.rejected': 'Возврат отклонен',
    'send.return.to.warehouse.etc': `Для получения средств, пожалуйста, отправьте возврат на склад {1}. После поступления возврата на склад, баланс вашего счет будет пополнен автоматически.`,
    'specify.reason.for.return': 'Укажите причину возврата',
    'reason.for.return': 'Причина возврата',
    'maximum.characters.count': 'максимум {1} символов',
    'product.photos': 'Фотографии товара',
    'choose.a.photo': 'Выберите фото',
    'searched.article': 'Искомый артикул',
    'sought.label': 'Искомый',
    'analogue.label': 'Аналог',
    'analogs.label': 'Аналоги',
    'no.results.found.for.request': 'По вашему запросу {1} ничего не найдено',
    'no.results.found.for.request.part1': 'По вашему запросу',
    'no.results.found.for.request.part2': 'ничего не найдено',
    'maybe.you.mean': 'Возможно вы имели ввиду',
    'several.positions.found': 'По вашему запросу найдено несколько разных позиций!',
    'no.offers': 'Предложения отсутствуют',
    'order.cancel.fail': 'Не удалось отменить заказ. Причина: ',
    'total.delivery.points.sm': 'Всего пунктов',
    'delivery.to.the.point': 'Доставка до ПВЗ',
    'pickup.from.stock': 'Самовывоз со склада',
};
const sup = {
    'preload.loading': 'Загрузка... ',
    'brand.label': 'Бренд',
    'part.number': 'Номер детали',
    'cost.label': 'Стоимость',
    'delivery.time': 'Срок поставки',
    'delivery.time.comma.days': 'Срок поставки, дн',
    'amount.of-tax': 'Сумма налога покупателю, ',
    'cost.with-tax': 'Стоимость с налогом, ',
    'country.code': 'Цифровой код страны',
    'country.name': 'Наименование страны',
    'customs.declaration': '№ таможенной декларации',
    'comments.label': 'Комментарии',
    'lines.quantity': 'Количество строк',
    'lines.qty': 'Кол-во строк',
    'invoice.abbr.number': 'С-ф №',
    'invoice.nubmer': 'Счёт-фактура №',
    'number.invoice': '№ счёта-фактуры',
    'first-line.product.label': 'Первая строка с товаром',
    'parts.product.label': '№ детали',
    'number.of.parts.label': 'Количество деталей',
    'unit.price.label': 'Цена за единицу',
    'cost.without.tax.label': 'Стоимость без налога',
    'amount.of.tax.label': 'Сумма налога',
    'country.code.label': 'Код страны',
    'cargo.customs.declaration.label': '№ ГТД',
    'legislative.tracking.code.label': 'Законодательное отслеживание код',
    'legislative.tracking.symbol.label': 'Законодательное отслеживание символ',
    'legislative.tracking.qty.label': 'Законодательное отслеживание кол-во',
    'download.response.label': 'Загрузить ответ',
    'send.label': 'Отправить',
    'setting.up.schedule.label': 'Настройка расписания',
    'mail.for.orders.label': 'Почта для заказов',
    'boxes.separated.by.space.placeholder': 'несколько ящиков через пробел',
    'hours-minutes.separated.by.space.placeholder': 'укажите часы:минуты через пробел',
    'bottom.upload.invoice.label': 'Загрузить счёт-фактуру',
    'bottom.file.download.label': 'Загрузка файла',
    'bottom.setting.label': 'Настройка',
    'bottom.processing.label': 'Обработка',
    'bottom.order.schedule.label': 'Расписание заказов',
    'loading.invoice.label': 'Загрузка счёт-фактуры',
    'invoice.file.label': 'Файл счёт-фактуры',
    'assign.invoice.cells.label': 'Назначьте ячейки счёта-фактуры:',
    'assign.cells.label': 'Назначьте ячейки :',
    'not.uploaded.invoice.label': 'Не загружена счёт-фактура',
    'go.to.settings.label': 'К настройкам',
    'process.label': 'Обработать',
    'time.of-sending.label': 'Время отправки',
    'file.no-name': 'без названия',
    'file.try.load.again': 'попробуйте ещё раз',
    'file.not.loaded': 'не загружен',
    //"no.data.label": "Нет данных",
    'try.again.label': 'Попробуйте еще раз',
    'file.load.completed.label': 'Файл загружен, настроен и обработан.',
    'change.settings.label': 'измените настройки',
    'or.label': 'или',
    'file.feminine.label': 'файла.',
    'for.amount.label': 'На сумму',
    'no.label': 'Нет',
    'yes.label': 'Да',
    'downloaded.label': 'Загружено',
    'downloaded.another.label': 'Загрузить другой',
    'download.file.label': 'Файл загружается...',
    'file.successfully.uploaded': 'Файл успешно загружен',
    'file.successfully.downloaded': 'Файл успешно загружен',
    'upload.label': 'Загрузить',
    'upload.another': 'Загрузить другой',
    'file.is.uploading': 'Файл загружается...',
    'lines.loaded': 'Строк загружено',
    'lines.successfully.loaded': 'Строк успешно загружено',
    'error.lines': 'Строк с ошибками',
    'unrecognized.brand.lines': 'Строк с нераспознанными брендами',
    'add.total.amount.parts.to-confirm.correctness': 'Укажите итоговую сумму товаров в файле для подтверждения корректности настроек:',
    'file.have.errors.to-fix.them': 'В файле имеются ошибки. Исправьте их и',
    'file.download': 'загрузите файл',
    'file.download.again': 'заново.',
    'amount.no-match': 'Сумма не совпадает.',
    'amount.matched.settings.correct': 'Сумма совпала - настройки корректны.',
    'file.processing.settings.saved': 'После обработки файла настройки сохранятся',
    'user.can.send.files.to-email': 'Вы можете присылать файлы счетов-фактур на почту',
    'split.by.order.number': 'разбить по № заказов',
    'ru.rouble.label': 'Российский рубль',
    'check.settings.label': 'Проверьте настройки',
    //"updated.label": "Обновлён",
    'currency.label': 'Валюта',
    'download.method.label': 'Метод загрузки',
    'manual.update.label': 'Ручное обновление',
    'external.file.label': 'Внешний файл',
    'not.recognized.label': 'Не распознано',
    'brands.label.genitive': 'брендов',
    'unrecognized.brands.label': 'Нераспознанные бренды',
    'compare.brands.from.price.list.label': 'Сопоставьте бренды из вашего прайс-листа с брендами из нашей базы',
    'compare.brands.label': 'Сопоставить бренды',
    'configure.label': 'Настроить',
    'server.label': 'Сервер',
    'select.connection.protocol': 'Выберете протокол подключения',
    'address.label': 'Адрес',
    'port.label': 'Порт',
    'port.desc': `Укажите порт для подключения, если вы не понимаете о чем речь, попробуйте оставить текущее значение, или задайте вопрос вашему администратору.`,
    'path.label': 'Путь',
    'select.path.to.file.label': 'Укажите путь к файлу относительно корня',
    'enter.login.for.connection.label': 'Укажите логин для подключения, если есть.',
    'enter.password.for.connection.label': 'Укажите пароль для подключения, если есть.',
    'time.label': 'Время',
    'time.pricelist.tooltip': `Укажите время обновления прайс листа в часах. Прайс лист будет обновляться каждый день, в указанное время.`,
    'price-lists.download.make.available': 'Загрузите прайс-листы, чтобы они появились в продаже',
    'price-lists.add': 'Добавить прайс-лист',
    'price-lists.upload.external.storage': 'Загрузите файл во внешнее хранилище, указанное в натройках,',
    'then.click.on.button': 'после этого нажмите на кнопку',
    'price-lists.check.settings.send.to.email': 'Для проверки настроек прайс листа, отправьте файл на почту,',
    'price-lists.error': 'Ошибка в прайс листе',
    'price-lists.delete': 'Удалить прайс-лист?',
    'user.details.label': 'Данные пользователя',
    'contact.phone.label': 'Контактный телефон',
    'download.utd.by.mail.label': 'Загрузка УПД по почте',
    'address.for.send.label': 'Адрес отправки',
    'file.name.label': 'Название файла',
    'file.name.lg.label': 'Наименование файла',
    'address.for.receiving.label': 'Адрес получения',
    'company.details.label': 'Реквизиты',
    'company.name.label': 'Название компании',
    'company.ein.label': 'ИНН',
    'corporate.registration.number.label': 'КПП',
    'corporate.registration.number.abbr': 'КПП',
    'bik.code.label': 'БИК',
    'payment.account.label': 'Расч. счёт',
    'payment.account.abbr': 'Р/с',
    'correspondent.account': 'Корреспондентский счёт',
    'correspondent.account.abbr': 'Кор. счёт',
    'legal.address.label': 'Юридический адрес',
    'legal.address.short': 'Юр. адрес',
    'bank.label': 'Банк',
    'bank.name': 'Название банка',
    'signatory.label': 'Подписант',
    'by.proxy': 'По доверенности',
    'by.proxy.abbr': 'пд.',
    'accountant.label': 'Бухгалтер',
    'including.vat': 'с НДС',
    'set.document.numbering': 'Настроить нумерацию документов',
    'prefix.label': 'Префикс',
    'start.numbering': 'Начало нумерации',
    'excel.format.upto.2mb': 'XLS или XLSX формата, до 2 Мб.',
    'file.uploading': 'Идёт загрузка файла...',
};
const wh = {
    'warehouse.addresses.short.label': 'Склад. адреса',
    'create.implementation': 'Создать реализацию',
    'create.refund': 'Создать возврат',
    'create.task': 'Создать задание',
    'task.on assemble': 'на сборку',
    'unlocking.label': 'Снятие блокировки',
    'take.off.label': 'Снять',
    'warehouse.label': 'Склад',
    'placement.label': 'Размещение',
    'packing.label': 'Сборка',
    'crossdocking.label': 'Кроссдокинг',
    'element.of.receipt.label': 'Элемент приёмки',
    'receipt.label': 'Приёмка',
    'issue.label': 'Выдача',
    'issue.btn': ' Выдать',
    'picking.type.label': 'Тип сборки',
    'shipment.label': 'Отгрузка',
    'stock.label': 'Сток',
    'stock.realTime.upload': 'Загружать по изменению',
    'stock.receipt.label': 'Приёмка стока',
    'quantity.limit': 'Ограничить максимальное кол-во шт',
    'add.btn': 'Добавить',
    'added.btn': 'Добавлено',
    'clear.btn': 'Очистить',
    'cells.storage': 'ячеек',
    'sector.label': 'Сектор',
    'floor.label': 'Этаж',
    'cells.label': 'Ячейки',
    'transfer.label': 'Трансфер',
    'select.clients.label': 'Выберите клиентов',
    'filter.label': 'Фильтр',
    'not.selected.label': 'не выбрано',
    'item.with.qr': 'Деталь с ШК',
    'item.not.found': 'не найдена',
    'item.already.assembled': 'уже собрана',
    // 'at.the.place': 'По местам',
    'by.places.label': 'По местам',
    'module.label': 'Блок',
    'block.label': 'Заблокировать',
    'to.block.short.label': 'Блок',
    'blocking.label': 'Блокировка',
    'cancel.blocking.label': 'Отмена блокировки',
    'blocked.label': 'Заблокированные',
    'to.verify.label': 'На проверку',
    'no.products': 'Товаров нет',
    'start.receipt': 'начните приёмку',
    'assign.label': 'Назначить',
    'assign.a.place': 'Назначить место',
    'assigning.a.place': 'Назначение местa',
    'select.the.place.number': 'Выберите номер места',
    'incoming.label': 'Входящие',
    'verify.label': 'Проверка',
    'search.by.article': 'Поиск по артикулу',
    'accepted.label': 'принятые',
    'to.accept.label': 'принять',
    'accepted.pieces.abbr': 'Принято, шт.',
    'how.many.units': 'Сколько единиц',
    'enter.quantity.label': 'Укажите количество',
    'enter.quantity.short.label': 'Укажите кол-во',
    'maximum.quantity': 'максимум',
    'receipt.time.label': 'Время приёмки',
    'cancel.receipt.label': 'Отмена приёмки',
    'select.stock.label': 'Выбор стока',
    'result.btn': 'Результат',
    'invoice.loaded.label': 'Инвойс загружен.',
    'invoice.label': 'Инвойс',
    'invoice.number': 'Номер инвойса',
    'simple.invoice': 'Упрощенный инвойс',
    'next.label': 'Далее',
    'assign.the.quantity.and.location': 'Назначьте кол-во и место',
    'buyer.refusal.block.product': 'Отказ покупателя, заблокируйте данный товар',
    'item.label': 'Деталь',
    'spread.label': 'Разложено',
    'reason.for.blocking.label': 'Причина блокировки',
    'actual.reason.label': 'Текущая причина',
    'reason.change.to.label': 'Сменить на',
    'decision.label': 'Решение',
    'on.stock.label': 'На сток',
    defective: 'Брак',
    counterfeit: 'Контрафакт',
    undersupply: 'Недовоз',
    loss: 'Утеря',
    refusal: 'Отказ',
    refund: 'Возврат',
    'qr.code': 'QR-код',
    'disconnected.plural': 'Отключенные',
    'disabled.plural': 'Отключенные',
    'shipments.label': 'Отгрузки',
    'create.shipment': 'Создайте отгрузки',
    'adding.position.to.utd': 'Добавление позиции в УПД №',
    'edit.utd': 'Редактирование УПД №',
    'add.position.label': 'Добавить позицию',
    'number.label': 'Номер',
    'creation.date.label': 'Дата создания',
    'default.markup': 'Наценка по-умолчанию',
    'default.markup.comma.percent': 'Наценка по-умолчанию, %',
    'change.margin.label': 'Изменить маржу',
    'change.price.label': 'Изменить цену',
    'change.quantity.label': 'Изменить количество',
    'download.prices.btn': 'Загрузить цены',
    'loading.prices.label': 'Загрузка цен',
    'file.with.prices': 'Файл с ценами',
    'price.change.history.label': 'История изменения цены',
    'switch.on.label': 'Включить',
    'switch.on.sm': 'вкл',
    'switch.off.label': 'Выключить',
    'switch.off.sm': 'выкл',
    'confirm.action': 'Подтвердите действие',
    'deselect.label': 'Снять выбор',
    'select.all.label': 'Выбрать все',
    'selected.label': 'Выбрано',
    'download.selected.lines': 'Скачать выделенные строки',
    'shift.label': 'Переместить',
    'range.label': 'Диапазон:',
    //'total.cells.label': 'Всего ячеек:',
    'this.area.label': 'Данная область:',
    'not.occupied.label': 'не занята',
    'print.label': 'Распечатать',
    'print.location.label': 'Распечатать место',
    'specify.range.addresses.or.single.cell': 'Укажите диапазон адресов или одну ячейку',
    'run.the.application': 'Запустите приложение',
    'download.the.app': 'Скачайте приложение.',
    'run.the.downloaded.app': 'Запустите скаченное приложение.',
    'select.the.printer': 'Выберите принтер',
    'save.the.printer': 'Сохраните принтер',
    'by.default.label': 'по умолчанию.',
    'printer.configuration.label': 'Настройки принтера',
    'from.supplier': 'От поставщика',
    'empty.stock.message.part1': `Ваш сток пуст. Чтобы товары появились на стоке, сперва загрузите их в разделе Приёмка, или перейдите по этой `,
    'empty.stock.message.part2': 'ссылке',
    'create.place': 'Создать место',
    'enter.code.issuing': 'введите код выдачи заказа',
    'code.correct.issuing': 'код верный, выдача заказа разрешена',
    'invalid.code.issuing': 'неверный код выдачи',
    'not.accepted.label': 'не принятые',
    'back.to.inbox.label': 'Назад к Входящим',
    'get.stock.dictionary.request.failed': 'Ошибка загрузки словаря стоков',
    // Receipt statuses
    'receipted.label': 'Принято',
    'placed.label': 'Размещено',
    'picked.label': 'Собрано',
    'packed.label': 'Упаковано',
    'added.to.realization.label': 'Добавленные в реализацию',
    'shipped.label': 'Отгружено',
    // Receipt
    'restore.label': 'Вернуть',
    // 'cancel.all.slash.restore.all': ' -  отменить все / восстановить все',
    'mark.all.label': ' - выбрать все',
    'choose.by.no': ' - выбрать по номеру',
    'receipt.revoked.successfully': 'Отмена приёмки',
    'receipt.revoke.failed': 'Ошибка отмены приёмки',
    'block.revoked.successfully': 'Блокировка снята успешно',
    'block.revoke.failed': 'Ошибка снятия блокировки.',
    'quantity.slash.accepted.slash.blocked.pcs': 'Кол-во / принято / блок, шт.',
    'quantity.slash.accepted.pcs': 'Кол-во / принято, шт.',
    'quantity.slash.available.pcs': 'Кол-во / доступно, шт.',
    'quantity.slash.available.slash.blocked.pcs': 'Кол-во / доступно / блок, шт.',
    'receipt.item.accepted': 'Позиция принята',
    'receipt.item.blocked': 'Позиция заблокирована',
    'receipt.item.reception.failed': 'Ошибка принятия позиции',
    'receipt.item.block.failed': 'Ошибка блокировки позиции',
    'start.entering.article.while.table.in.focus': 'Начните вводить артикул находясь в фокусе таблицы',
    'receipt.invoice.copy.cell.value.to.clipboard.first.part': 'Для того, чтобы скопировать в буфер обмена значение ячейки, например',
    'receipt.invoice.copy.cell.value.to.clipboard.second.part': ', просто кликните мышкой на это значение как по ссылке.',
    'block.reason.does.not.allow.to.unblock.item': 'Причина блокировки не позволяет разблокировать позицию',
    'received.quantity.more.than.available': 'Указанное кол-во для приемки больше чем доступно',
    'blocked.quantity.more.than.available': 'Указанное кол-во для блокировки больше чем доступно',
    'get.sticker.items.request.failed': 'Ошибка запроса стикеров',
    'get.stock.receipt.items.request.failed': 'Ошибка получения списка позиций',
    'the.specified.place.is.no.longer.available': 'Указанное место более недоступно',
    'hotkeys.label': 'Горячие клавиши',
    'hotkey.label': 'Горячая клавиша',
    'search.field.clearing': 'Очистка поиска',
    'switch.to.english.language': 'Переключите на английский',
    'upload.invoice.label': 'Загрузить счёт-фактуру',
    'invoice.uploaded.successfully': 'Счёт-фактура успешно загружена',
    'no.options.label': 'Нет совпадений',
    'upload.invoice.dialog.step.stock.select.label': 'Выбор стока',
    'upload.invoice.dialog.step.stock.file.upload.label': 'Загрузка файла',
    'upload.invoice.dialog.step.stock.cell.mapping.label': 'Настройка',
    'article.position.in.string': 'Выбрать позицию № детали в строке',
    'whole.string.is.article': 'Строка целиком',
    'in.the.start.of.the.string.divided.by.whitespace': 'В начале строки, отделено пробелом',
    'in.the.end.of.the.string.divided.by.whitespace': 'В конце строки, отделено пробелом',
    'in.round.brackets.anywhere.in.the.line': 'В круглых скобках',
    'stock.receipt.invoice.setting.key.invoice.id.cell': '№ счёта-фактуры',
    'stock.receipt.invoice.setting.key.date.cell': 'Дата',
    'stock.receipt.invoice.setting.key.article.column': '№ детали',
    'stock.receipt.invoice.setting.key.start.row': 'Первая строка с товаром',
    'stock.receipt.invoice.setting.key.qty.column': 'Количество деталей',
    'stock.receipt.invoice.setting.key.price.column': 'Цена за единицу',
    'stock.receipt.invoice.setting.key.brand.column': 'Бренд',
    'stock.receipt.invoice.setting.key.name.column': 'Имя',
    'stock.receipt.invoice.setting.key.country.code.column': 'Код страны',
    'stock.receipt.invoice.setting.key.country.gtd.column': '№ ГТД',
    'stock.receipt.invoice.setting.key.tracking.code.column': 'Законодательное отслеживание код',
    'stock.receipt.invoice.setting.key.tracking.qty.column': 'Законодательное отслеживание кол-во',
    'stock.receipt.invoice.setting.key.tracking.symbol.column': 'Законодательное отслеживание символ',
    'one.of.settings.is.not.defined': 'Не все настройки готовы к отправке',
    'you.should.define.all.invoice.settings.first': 'Вам следует определить все настройки счёта-фактуры, у каждой настройки должна стоять зелёная галочка',
    // Placement
    'get.placement.items.request.failed': 'Ошибка в получении списка позиций для размещения',
    'get.placement.cells.request.failed': 'Ошибка в получении списка ячеек',
    'remove.blocking.label': 'Снять блокировку',
    'placement.item.block.failed': 'Ошибка блокировки',
    'placement.item.unblock.failed': 'Ошибка снятия блокировки',
    'placement.item.assign.failed': 'Ошибка назначения места',
    // Packing
    'back.to.packing': 'Назад к Сборке',
    'get.packing.items.request.failed': 'Ошибка получения списка позиций для упаковки',
    'get.customers.for.packing.request.failed': 'Ошибка получения списка клиентов для упаковки',
    'start.packing.for.users.request.failed': 'Ошибка при запросе на упаковку для клиентов',
    'get.packing.details.request.failed': 'Ошибка при получении деталей сборки',
    'save.delivery.box.request.failed': 'Ошибка при сохранении сборки',
    'create.realization.request.failed': 'Ошибка при создании реализации',
    'pack.detail.sticker.request.failed': 'Ошибка при отправке стикера в сборку',
    'unpack.detail.sticker.request.failed': 'Ошибка при удалении стикера из сборки',
    'realization.created.successfully': 'Реализация успешно создана',
    // Shipment
    'box.label': 'Коробка',
    'resend.document.label': 'Переотправить документ',
    'get.shipments.query.failed': 'Ошибка получения отгрузок',
    'document.has.been.successfully.resent': 'Документ успешно переотправлен',
    'document.resend.failed': 'Ошибка переотправки документа',
    'delivery.box.id.label': 'ID коробки',
    'utd.{1}.has.been.successfully.edited': 'УПД №{1} успешно отредактирован',
    'utd.update.error': 'Ошибка редактирования УПД',
    'adding.positions.to.the.box': 'Добавление позиций в коробку',
    // 'added.stickers': 'Добавленные стикеры',
    'added.positions.label': 'Добавленные позиции',
    'existed.positions.label': 'Изначальные позиции',
};
const ru = { ...core, ...shared, ...adm, ...main, ...sup, ...wh };
export { ru };
