import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from 'core/ui/TextField';
import { floatPositiveNumberLowRestriction, integerNumberRegex, wholePositiveAndNegativeNumberWithSingleMinusRegex, } from 'core/utils/validation';
import { forwardRef } from 'react';
import { t } from 'shared/translations';
const NumericField = forwardRef(({ variant = 'whole', type, onChange, ...props }, ref) => {
    const adornment = type
        ? {
            endAdornment: type === 'currency'
                ? t('$')
                : type === 'percent'
                    ? '%'
                    : type === 'pieces'
                        ? t('pieces.abbr')
                        : type === 'days'
                            ? t('days.abbr')
                            : undefined,
        }
        : {};
    return (_jsx(TextField, { ref: ref, ...props, ...adornment, onChange: (event) => {
            const value = event.target.value;
            if (value === '') {
                onChange(null);
                return;
            }
            switch (variant) {
                case 'whole': {
                    if (integerNumberRegex.test(value)) {
                        // -- @ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        onChange(Number(value));
                    }
                    break;
                }
                case 'wholeNegative': {
                    if (wholePositiveAndNegativeNumberWithSingleMinusRegex.test(value)) {
                        // -- @ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        if (value === '-')
                            onChange(value);
                        // -- @ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        else
                            onChange(Number(value));
                    }
                    break;
                }
                case 'floatPositive': {
                    if (floatPositiveNumberLowRestriction.test(value)) {
                        // --@ts-expect-error ts could infer right type of `onChange` according to `variant` prop
                        onChange(value);
                    }
                    break;
                }
            }
        } }));
});
NumericField.displayName = 'NumericField';
export { NumericField };
