import { alpha } from '@mui/material';
export const MuiAvatar = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            borderRadius: '20%',
            outlineColor: alpha(theme.palette.common.black, 0.2),
            outlineOffset: '-1px',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            verticalAlign: 'middle',
        }),
    },
};
