import { TableCell } from 'core/styled';
import { styled } from '@mui/material';
export const TableCellCrossedOut = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'crossed' })(({ theme, crossed }) => crossed
    ? {
        position: 'relative',
        color: crossed ? theme.palette.text.disabled : theme.palette.text.secondary,
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '0',
            right: '0',
            height: '1px',
            background: theme.palette.text.medium,
        },
    }
    : undefined);
