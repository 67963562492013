import { linearProgressClasses } from '@mui/material';
export const MuiLinearProgress = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            height: '.5rem',
            borderRadius: '9999px',
            backgroundColor: theme.palette.neutral.lighter,
        }),
        colorPrimary: ({ ownerState, theme }) => ({}),
        bar: ({ ownerState, theme }) => ({
            // [`.${linearProgressClasses.root}[aria-valuenow="100"] &`]: {
            // 	backgroundColor: theme.palette.success.main,
            // },
            [`&.${linearProgressClasses.barColorPrimary}`]: {
                backgroundColor: theme.palette.primary.dark,
            },
            [`&.${linearProgressClasses.barColorSecondary}`]: {
                backgroundColor: theme.palette.secondary.dark,
            },
            // There  are no acceptable classes in linearProgressClasses! Only primary and secondary are allowed.
            '&.MuiLinearProgress-barColorSuccess': {
                backgroundColor: theme.palette.success.main,
            },
            '&.MuiLinearProgress-barColorError': {
                backgroundColor: theme.palette.error.dark,
            },
        }),
    },
};
