/**
 * Should be used instead of Mui's `styled` function second parameter with manual props excluding.
 *
 * @example
 * ````
 *  styled(Paper, { shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'transition' })({...})
 * ````
 * could be replaced with
 * ````
 *  styled(Paper, shouldNotForwardProps('disabled', 'transition'))({...})
 * ````
 * @param args
 * @returns
 */
export function shouldNotForwardProps(...args) {
    return {
        shouldForwardProp: (prop) => !args.includes(prop),
    };
}
