export const MuiFormHelperText = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            lineHeight: 1.5,
            color: theme.palette.text.disabled,
            margin: '.25rem',
            minHeight: '.25rem',
        }),
    },
};
