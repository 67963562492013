import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogContentContainer, LoadingDialogContent } from './DialogContentBackdrop.styled';
import { CircularProgressBackdrop } from '../CircularProgressBackdrop';
import { DialogLoaderBackdrop } from './components';
/**
 * Use {@link DialogContentBackdrop} instead of MUI native {@link DialogContent} component to block the dialog paper with a backdrop
 * while the form is submitting and show {@link CircularProgressBackdrop} in center of the dialog.
 *
 * Also, spinner is show on data fetching.
 * @example
 * ```tsx
 *  <DialogContentBackdrop isSubmitting={isSubmitting} isFetching={isFetching}>
 *    <FormControl>...</FormControl>
 * 		// Other controls...
 *    <FormControl>...</FormControl>
 *  </DialogContentBackdrop>
 * ```
 *
 * @returns
 */
const DialogContentBackdrop = ({ isSubmitting, isFetching, ...dialogContentProps }) => {
    // `!isSubmitting` prevents loader to be shown on getQuery refetch during invalidation when form is submitted.
    // Loader and submission progress both are shown without this fix.
    const showLoadingSpinner = isFetching && !isSubmitting;
    return (_jsxs(DialogContentContainer, { children: [_jsx(LoadingDialogContent, { ...dialogContentProps, isLoading: showLoadingSpinner }), _jsx(DialogLoaderBackdrop, { open: showLoadingSpinner }), _jsx(CircularProgressBackdrop, { dialog: true, open: isSubmitting })] }));
};
export { DialogContentBackdrop };
